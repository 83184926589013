import React, { Component } from "react";
import { Container, Col, Row } from 'bootstrap-4-react';
import { instaphone } from "../../../Components/UI/Library";

// import {translate} from "react-i18next";
class HowToPlay extends Component {
    constructor() {
        super();
        this.state = {
            playOptions: 'visible',
            // online: 'hidden',
            // tigo: 'hidden',
            // vodacom: 'hidden',
            // airtel: 'hidden',
            // halotel: 'hidden'
        }
    }
    openOnline=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            // online: 'visible',
            // tigo: 'hidden',
            // vodacom: 'hidden',
            // airtel: 'hidden',
            // halotel: 'hidden'
        })
    }
    openTigo=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'visible',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'hidden'
        })
    }
    openVodacom=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'visible',
            airtel: 'hidden',
            halotel: 'hidden'
        })
    }
    openAirtel=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'visible',
            halotel: 'hidden'
        })
    }
    openHalotel=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'visible'
        })
    }
    goBack=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'visible',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'hidden'
        })
    }
    render() {
        const { t, i18n } = this.props;
        return (
            <div className="insta-how">
                 <div className={this.state.playOptions + " play-methods instalotto"}>
                    <div className="playonline">
                        <ul>
                            <li><i>1</i><span>{"CHOISISSEZ 3 CHIFFRES OU CLIQUEZ SUR LES DÉS POUR CHOISIR DE FAÇON ALÉATOIRE"}</span></li>
                            <li><i>2</i><span>{"ATTENDEZ LE COMPTE À REBOURS"}</span></li>
                            <li><i>3</i><span>{"CONSULTEZ LA SECTION DES GAINS À LA FIN DU DECOMPTE"}</span></li>
                        </ul>
                    </div>
                </div>
                <div className={"pick3 instalotto " + this.state.online}>
                    <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {"Back"}</a>
                    <div className="playonline">
                        <ul>
                            <li><i>1</i><span>{"CHOISISSEZ 3 CHIFFRES OU CLIQUEZ SUR LES DÉS POUR CHOISIR DE FAÇON ALÉATOIRE"}</span></li>
                            <li><i>2</i><span>{"ATTENDEZ LE COMPTE À REBOURS"}</span></li>
                            <li><i>3</i><span>{"CONSULTEZ LA SECTION DES GAINS À LA FIN DU DECOMPTE"}</span></li>
                        </ul>
                    </div>
                </div>
                <div className={"pick3 instalotto " + this.state.tigo}>
                    <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {"Back"}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7" offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{"Dial"} *150*01#</span></li>
                                    <li><i>2</i><span>{"Select pay Bills"}</span></li>
                                    <li><i>3</i><span>{"Enter Company Number"} 123255</span></li>
                                    <li><i>4</i><span>{"Enter Reference numbers (Your 3 lucky numbers)"}</span></li>
                                    <li><i>5</i><span>{"Enter amount"}</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%" />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={"pick3 instalotto " + this.state.vodacom}>
                    <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {"Back"}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7" offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{"Dial"} *150*00#</span></li>
                                    <li><i>2</i><span>{"Pay for M-Pesa"}</span></li>
                                    <li><i>3</i><span>{"Enter Company Number"} 123255</span></li>
                                    <li><i>4</i><span>{"Enter Reference numbers (Your 3 lucky numbers)"}</span></li>
                                    <li><i>5</i><span>{"Enter amount"}</span></li>
                                    <li><i>6</i><span>{"Enter Your Mpesa pin"}</span></li>
                                    <li><i>7</i><span>{"Click 1 to confirm"}</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%" />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={"pick3 instalotto " + this.state.airtel}>
                    <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {"Back"}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7" offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{"Dial"} *150*60#</span></li>
                                    <li><i>2</i><span>{"Select pay Bills"}</span></li>
                                    <li><i>3</i><span>{"Enter Company Number"} 123255</span></li>
                                    <li><i>4</i><span>{"Enter amount"}</span></li>
                                    <li><i>5</i><span>{"Enter Reference numbers (Your 3 lucky numbers) "}</span></li>
                                    <li><i>6</i><span>{"Enter Secret Number"}</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%" />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={"pick3 instalotto " + this.state.halotel}>
                    <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {"Back"}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7" offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{"Dial"} *150*88#</span></li>
                                    <li><i>2</i><span>{"Pay for Halopesa"}</span></li>
                                    <li><i>3</i><span>{"Enter Company Number"} 123255</span></li>
                                    <li><i>4</i><span>{"Enter Reference numbers (Your 3 lucky numbers)"}</span></li>
                                    <li><i>5</i><span>{"Enter amount"}</span></li>
                                    <li><i>6</i><span>{"Enter the secret code to verify"}</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%" />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
export default (HowToPlay);
