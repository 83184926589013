import React, { Component } from "react";
import Countdown from "./Countdown";
import axios from "axios";
import { pick3LogoStatic } from "../../../Components/UI/Library";
import { pick3drawsApi } from "../../../General/Api";
import i18next from "i18next";

class PickSideMenu extends Component {
    constructor() {
        super();
        this.state = {
            menu: '',
            instalotto: []
        }
        this.openMenu = this.openMenu.bind(this);
    }
    async getInstalotto() {
        let url=pick3drawsApi(false,0,1,i18next.language);
        const res = await axios(url);
        return res.data.draw;
    }
    componentDidMount() {
        this.setState({loading: true});
        (async () => {
            try {
                this.setState({instalotto: await this.getInstalotto()});
                this.setState({loading: false});
            } catch (e) {
                this.setState({loading: false});
            }
        })();
    }
    openMenu(event) {
        event.preventDefault();
        if (this.state.menu === '') {
            this.setState({
                menu: 'open'
            })
        } else {
            this.setState({
                menu: ''
            })
        }
    }

    render() {
        let {onClick, active} = this.props;
        let balance = '';
        let menu = (
            <ul className={active + " out"}>
                <li onClick={this.openMenu}><a href="#" onClick={onClick} name="play">{"Jouer"}</a></li>
                <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtoplay">{"Comment jouer ?"}</a></li>
                <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtowin">{"Comment gagner ?"}</a></li>
                <li onClick={this.openMenu}><a href="#" onClick={onClick} name="winners">{"Résultats"}</a></li>
            </ul>
        );
        if (this.props.loggedIn) {
            balance =  <div className="available-balance">
                <span>{"Solde disponible"}</span>
                <h3>{this.props.balance + " FCFA"}</h3>
            </div>;
            menu = (
                <ul className={active}>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="play">{"Jouer"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtoplay">{"Comment jouer ?"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtowin">{"Comment gagner ?"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="purchases">{"Achats"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="winnings">{"Mes gains"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="winners">{"Résultats"}</a></li>
                </ul>
            );
        }
        return (
            <ul>
                <div className={this.state.menu + " game-sidemenu pick3-sidemenu"}>
                    <div className="side-info-container">
                        <a className="fa fa-align-justify only-mobile" href="#" onClick={this.openMenu}></a>
                        {balance}
                        <div className="game-logo">
                            <img src={pick3LogoStatic} width="100%"/>
                        </div>
                        <div className="draw-countdown">
                            <p className="desktop-only">{"Temps restant au prochain tirage"}</p>
                            <p className="non-desktop">{"prochain tirage"}</p>
                            <Countdown />
                        </div>
                        {menu}
                    </div>

                </div>
            </ul>

        );
    }
}

export default (PickSideMenu);

