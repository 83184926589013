
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {translate} from "react-i18next";

class SpinWinLogin extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="game-subcontainer game-loggedout">
                <div className="sratch-logout">
                    {/* <h2>{t("Tourne ta chance")}</h2> */}
                    <h4>{t("TOURNE TA CHANCE ET GAGNE JUSQU’À 1.000.000 FCFA")}</h4>
                    <Link className="login-game" to="/login">{t("CONNECTEZ-VOUS ET JOUEZ")}</Link>
                </div>
                <div className="extra-scratch spw absolute"></div>
            </div>
        );

    }
}
export default translate("translations")(SpinWinLogin);
