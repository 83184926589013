import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { RemoveComma, SplitAmount } from "../../../Components/Functions";


class HowToWin extends Component {
  render() {
    const { t } = this.props;
    let howtowin = (
      <span className="game-not-available">
        LE JEU N'EST PAS DISPONIBLE AU MOMENT
      </span>
    );

    if (this.props.prizevalues) {
      console.log('prizee',this.props.prizevalues);
      howtowin = (
        <ul>
          {this.props.prizevalues.map((value, index) => (
            <li key={index}>
              <i>{index + 1}</i>
              <img src={`${process.env.PUBLIC_URL}bingo/${value.value}.png?${Math.random()}`} />
              <img src={`${process.env.PUBLIC_URL}bingo/${value.value}.png?${Math.random()}`} />
              <img src={`${process.env.PUBLIC_URL}bingo/${value.value}.png?${Math.random()}`} />
              <span>{RemoveComma(SplitAmount(value.prize))} FCFA</span>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <div className="insta-how scratch-how">
        <div className="pick3" style={{ display: "block" }}>
          <div className="play-method online">
            <div className="scratch-howtowin">
              <h2>{t("DECOUVREZ TROIS (3) LETTRES IDENTIQUES ET GAGNEZ UNE (1) FOIS LE MONTANT ASSOCIÉ")}</h2>
              {howtowin}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    prizevalues: state.auth.ba_prizeValues,
  };
};

export default connect(mapStateToProps)(translate("translations")(HowToWin));
