import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import {translate} from "react-i18next";
import axios from 'axios';
import { SplitAmount } from '../Functions';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

class Wallet extends Component {
    constructor(props) {
        super();

        this.state = {
            walletBlock: '',
        }
    }

    render() {
        const { t, i18n } = this.props;
        let wallet = null;
        if ( this.props.isAuthenticated ) {
            wallet = (
              <div  className="header-wallet " >
                  <a className="close-wallet" onClick={this.props.onClose} href="#"><i className="fa fa-times-circle"></i> </a>
                  <div>
                    
                        <h5>{this.props.account}</h5>
                </div>

                  <div>
                      <span>{"total"}</span>
                      <h3>{this.props.totalAmount} FCFA</h3>
                  </div>
                  <div>
                      <span>{"gain"}</span>
                      <h3>{this.props.winningAmount} FCFA</h3>
                  </div>
           
                <div>
                <span  className="wallet-header">
            <p style={{ padding: 0, margin: 0, color: "green", cursor:"pointer" }} onClick={()=>{this.props.logout()}} className="header-wallet-username">Se déconnecter</p>
            </span>
                    {/* <Link className="cash-btn deposit" to="/account/bank/deposit">{t("DÉPÔT")}</Link>
                    <Link className="cash-btn cashout" to="/account/bank/cashout">{t("RETRAIT")}</Link> */}
                </div>
              </div>
            );
        }
        return (
            <div>
            {wallet}

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        wallet: state.auth.wallet,
        promoWallet: state.auth.promoWallet,
        isAuthenticated: state.auth.token !== null
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() ),
        onGetWallet: () => dispatch(actions.getWallet()),
        logout:()=>dispatch(actions.endSession())
    
    };
};
export default  connect( mapStateToProps, mapDispatchToProps )(translate("translations")( Wallet ));
