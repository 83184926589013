import React, { Component } from 'react';
import * as actions from "../../../store/actions";
import connect from "react-redux/es/connect/connect";
import NumericInput from "react-numeric-input";
import {translate} from "react-i18next";
import ReactGA from "react-ga";
import axios from "axios";
import { pick3drawsApi } from '../../../General/Api';
import i18next from 'i18next';

const pick3detailsUrl=pick3drawsApi(false,0,1,i18next.language);


class PickPlay extends Component {
    constructor() {
        super();
        this.state = {
            number1: '',
            number2: '',
            number3: '',
            minStake:null,
            maxStake:null,
            stake: null,
            rotate: false,
            buyingms: null,
            price: 200,
            loading: false,
        };
    }

    handleChangeNumber = (event,number,next) => {
        const max_chars = 1;
        let value=event.target.value;
        const re = /^[0-9\b]+$/;

        if(value.length > max_chars) {
            re.test(value[value.length-1])?
                value=value[value.length-1]
                :
                value =value.substr(0, max_chars);
        }
  
        if (value === '' || re.test(value)) 
            {
                this.setState({ [number]: value });
                if(next!="" && value!='')this.refs[next].focus();}
    };

    handleInput = (evt)=>{
        return (["e", "E", "+", "-", "."]).includes(evt.key) && evt.preventDefault();
    }

    purchasePick3 = ( event ) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let numbers = this.state.number1 + ',' + this.state.number2  + ',' + this.state.number3;
        this.props.onPurchasePick3(this.state.stake, numbers, localStorage.getItem('token'));
        ReactGA.event({
            category: 'Games',
            action: 'Play Pick3'
        });

    };
    generateRandom=(event)=> {
        event.preventDefault();
        const min = 0;
        const max = 9;
        const rand1 = min + Math.random() * (max - min);
        const rand2 = min + Math.random() * (max - min);
        const rand3 = min + Math.random() * (max - min);

        this.setState({
            rotate: true,
            number1: Math.round(rand1),
            number2: Math.round(rand2),
            number3: Math.round(rand3),
        });
    }

    handleChange=(value)=> {
       
        const {minStake,maxStake,stake}=this.state;
        let newValue=value;

        if(value>maxStake)
        {
            newValue=maxStake
        }
        else if(value<minStake){
            newValue=minStake
        }

        this.setState({
            stake: newValue
        });      
    }

    componentDidMount=async ()=>{
        const res = await axios(pick3detailsUrl);
        if(res.data.status==1)
        {
            let draw=res.data.draw;
            this.setState({
                minStake:draw.minStake,
                maxStake:draw.maxStake,
                stake:draw.minStake
            });
        }
 
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('statusUpdtae', this.props.status);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('status', nextProps.status);
        if (nextProps.status == 1) {
            this.setState({
                buyingms: <div className="success"  style={{marginTop: '35px'}}>
                    <i className="fa fa-check"></i>
                    <span>Billets achetés avec succès </span>
                </div>,
                loading: false,
                number1:'',
                number2:'',
                number3:''
            })
        } else if(nextProps.message)  {
            this.setState({
                buyingms: <div className="error" style={{marginTop: '35px'}}>
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>{nextProps.message}</span>
                </div>,
                loading: false
            })
        }
        console.log('hona error', this.props);
    }

    render() {
        const {minStake,stake}=this.state;
        const rotate = this.state.rotate;
        let loading = false;
        let disabled = null;
        if (this.state.loading) {
            disabled = "disabled";
             loading = <div className="loading-container"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        return (
            <div className="game-subcontainer">
                <div className="pick3-game">
                    <h2>{"GAGNEZ JUSQU'À 250 FOIS VOTRE PIÈCE"}</h2>
                    <h4>{"SI VOUS OBTENEZ 2 OU 3 BONS NUMÉROS \n" +
                    " "}</h4>
                    <h4>{"ENTRE 0 ET 9" +
                    " "}</h4>
                    <div className="pick3-fields">
                            <div className="game-fields">
                                {/* <label>{"CHOISISSEZ VOS NUMÉROS PRÉFÉRÉS ENTRE 0 ET 9 ET GAGNEZ AU TIRAGE !"}</label> */}
                                <label>{"QUELS SONT VOS 3 NUMEROS CHANCEUX?"}</label>
                                <div>
                                    <input onKeyDown={ (evt) => this.handleInput(evt) } required={true} onChange={(e)=>this.handleChangeNumber(e,"number1","num2")} type="number" value={this.state.number1} name="num1" ref="num1" min="0" max="9"/>
                                    <input onKeyDown={ (evt) => this.handleInput(evt) } required={true} onChange={(e)=>this.handleChangeNumber(e,"number2","num3")} type="number" value={this.state.number2} name="num2" ref="num2" min="0" max="9"/>
                                    <input onKeyDown={ (evt) => this.handleInput(evt) } required={true} onChange={(e)=>this.handleChangeNumber(e,"number3","num1")} type="number" value={this.state.number3} name="num3" ref="num3" min="0" max="9"/>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="pick3-random">
                                <span>{"RANDOMISER VOS NUMÉROS"}</span>
                                <a href="#"
                                   onClick={this.generateRandom}
                                   onAnimationEnd={() => this.setState({ rotate: false })}
                                   className={rotate ? 'rotate' : ''}
                                ><i className="dice"></i></a>
                            </div>
                            <div className="game-fields">
                                <label>{"Votre Mise"}</label>
                                <input type="number" className="form-control"  required={true} onChange={this.handleChange}  value={stake}/>
                            </div>
                            <div className="game-fields">
                                {/* <button >jouer<button/> */}
                                <button onClick={this.purchasePick3} className={disabled + " main-btn"}>
                                    jouer
                                </button>
                            </div>
                            {loading}
                            {this.state.buyingms}
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        wallet: state.auth.wallet,
        message: state.auth.pick3Message,
        status: state.auth.pick3status,
        loading: state.auth.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPurchasePick3: (stake,pickNumbers, token ) => dispatch( actions.purchasePick3(stake,pickNumbers, token ) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(translate("translations")( PickPlay ));