//given amount: 2,000.00 >> it will return 2,000
export const SplitAmount = (amount) => {
  return amount?amount.split(".")[0]:amount;
};

//given a string: 2,000>> it will return 2 000
export const RemoveComma = (value) => {
  return value?value.replace(/,/g, ' ') :value;
};





