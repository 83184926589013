import React, { Component } from "react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import {RemoveComma, SplitAmount} from '../../../Components/Functions';


class HowToWin extends Component {
  render() {
    const { t } = this.props;
    let howtowin = (
      <span className="game-not-available">
        LE JEU N'EST PAS DISPONIBLE AU MOMENT
      </span>
    );

    if (this.props.prizevalues) {
      howtowin = (
        <ul>
          {this.props.prizevalues.map((value, index) => (
            <li key={index}>
              <i>{index + 1}</i>
              <img
                src={`${process.env.PUBLIC_URL}lucky/${value.value}.png`}
              />
              <img
                src={`${process.env.PUBLIC_URL}lucky/${value.value}.png`}
              />
              <img
                src={`${process.env.PUBLIC_URL}lucky/${value.value}.png`}
              />
              <span>{RemoveComma(SplitAmount(value.prize))} FCFA</span>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <div className="insta-how scratch-how">
        <div className="pick3" style={{ display: "block" }}>
          <div className=" online" className="play-method">
            <div className="scratch-howtowin">
              {/* <h2>{t("jouer et gagner!")}</h2> */}
              <h4>{t("TROUVEZ TROIS (3) FORMES IDENTIQUES ET GAGNEZ UNE (1) FOIS LE MONTANT ASSOCIÉ ")}</h4>
              {howtowin}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    prizevalues: state.auth.l7_prizeValues,
  };
};
export default connect(mapStateToProps)(translate("translations")(HowToWin));
