import React, { Component } from "react";
// import PropTypes from 'prop-types';
import axios from "axios";
import { pick3drawsApi } from "../../../General/Api";
import i18next from "i18next";
// import {translate} from "react-i18next";

/**
 * Note :
 * If you're using react v 15.4 or less
 * You can directly import PropTypes from react instead.
 * Refer to this : https://reactjs.org/warnings/dont-call-proptypes.html
 */

const url = (lang)=> pick3drawsApi(false,0,1,lang);

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      instalottoDate: null,
      loading: true,
    };
  }
  async getPick3Draw() {
    console.log(">>>>",url(i18next.language))
    const res = await axios(url(i18next.language));
    return res.data.draw.drawTime;
  }

  updateTime = async () => {
    let res = await this.getPick3Draw();
    let date = false;

    if (res)
      this.interval = setInterval(async () => {
        date = await this.calculateCountdown(res);

        date ? this.setState(date) : this.stop();
      }, 1000);
  };

  componentDidMount() {
    this.updateTime();
  }
  // componentWillUpdate(nextProps, nextState, nextContext) {
  //     if (nextState.days == 0 && nextState.hours == 0 && nextState.min == 0 && nextState.sec == 1 ) {
  //         this.getDrawDate();
  //     }
  // }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff < 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;
    this.setState({
      loading: false,
    });
    return timeLeft;
  }

  stop = () => {
    clearInterval(this.interval);
    this.updateTime();
  };

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;
    let loading = false;
    if (this.state.loading) {
      loading = (
        <div className="loading-container overlay">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      );
    }
    return (
      <div className="Countdown loadable-block" style={{ minHeight: "0" }}>
        {loading}
        <span className="Countdown-col days">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(countDown.days)}</strong>
            <span>{countDown.days === 1 ? "Day" : "Days"}</span>
          </span>
        </span>

        <span className="Countdown-col hour">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(countDown.hours)}</strong>
            <span>{"Hours"}</span>
          </span>
        </span>

        <span className="Countdown-col min">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(countDown.min)}</strong>
            <span>{"Min"}</span>
          </span>
        </span>

        <span className="Countdown-col sec">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(countDown.sec)}</strong>
            <span>{"Sec"}</span>
          </span>
        </span>
      </div>
    );
  }
}

export default Countdown;
