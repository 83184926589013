
import React, { Component } from 'react';
import ReactGA from "react-ga";
import axios from "axios";
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import { translate} from "react-i18next";
import { wheel } from '../../../Components/UI/Library';
import { scratchApi } from '../../../General/Api';


const url =(token)=> scratchApi(token,5,0,1000,0);

class SpinWinPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotate: null,
            confetti: false,
            ticketId: null,
            serialNumber: null,
            ticketValues: [],
            winning: false,
            celerate: false,
            prize: null,
            loadingscratch: false,
            firstAttempt: 0,
            winningExtra: false,
            celebrate: null,
            disable: ''
        };

    }
    getTicket=(reload)=>{
        axios.get(url(localStorage.getItem("token")))
        .then(response => {
        
            if(response.data.status === 1 && response.data.tickets.length > 0) {
              
                let stateBody={
                    ticketId: response.data.tickets[0].id,
                    serialNumber: response.data.tickets[0].serialNumber,
                    ticketValues: response.data.tickets[0].ticketValues,
                    winningValue: response.data.tickets[0].winningValue,
                    winningprize: response.data.tickets[0].prize,    
                };

                if(reload)
                {
                    this.props.onPlayTicket(this.props.gameId, response.data.tickets[0].id, this.state.serialNumber, 5);
                    stateBody={
                        ...stateBody,
                        button: '',
                        showprize:'clicked'
                    };
                }
                if (response.data.tickets[0].winningValue != 'losing') {
                    stateBody={
                        ...stateBody,
                        v: response.data.tickets[0].winningValue,
                        celebrate: 1,
                        prize: this.state.winningprize,
                    };
                }
                else {
                    var losingClasses= new Array("losing1","losing2","losing3");
                    var random = losingClasses[Math.floor(Math.random() * losingClasses.length)];
                  
                    stateBody={
                        ...stateBody,
                        v: random,
                        celebrate: 2,
                        prize: null,
                    };
    
                }

                this.setState(stateBody);
            }
            else {
             console.log("elseeee????",response)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
        clearInterval()
    }
    reset() {
        this.timeout = setInterval(() => {
            this.setState({ v: 'reset', celebrate: null });
        }, 6000);
    }
    spinWheel=async (event)=> {
        event.preventDefault();
        ReactGA.event({
            category: 'Games',
            action: 'Play Tourn ta Chance'
        });
        this.setState({

            celebrate: null,
            showprize: '',
            disable: 'disable'
        });        

        setTimeout(() => this.setState({ disable: '' }), 5000);

        this.setState({
            v: this.state.winningValue,
            firstAttempt: 1,
            buyingms: null,
            ticketId:null,
            serialNumber:null,
            ticketValues:null,
            winningValue:null,
            winningprize:null,
        });
        this.getTicket(true);
   
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.statussw == 1) {
            this.setState({
                buyingms: <div className="success" >
                    <i className="fa fa-check"></i>
                    <span>Tickets achetés avec succès</span>
                </div>
            })
        } else if (this.props.statussw == 0) {
            this.setState({
                buyingms: <div className="error" style={{marginTop: '35px'}}>
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>Une erreur s'est produite, veuillez réessayer plus tard</span>
                </div>
            })
        }
        else if (this.props.statussw == 5) {
            this.setState({
                buyingms: <div className="error" style={{marginTop: '35px'}}>
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>Montant insuffisant dans votre portefeuille</span>
                </div>
            })
        }
        if (this.props.statussw == 99) {
            this.setState({
                buyingms:null
            })
        }
    }
    componentWillUnmount() {
        this.setState({
            buyingms: null
        })
    }

    render() {
        const { t } = this.props;
        console.log("propss",this.props)
        let button = <a className={this.state.disable + " spin"} href="#"  name="play" onClick={this.spinWheel} >{t("Tourne ta chance")}</a>;
        let { onBuy} = this.props;
        let winningPrize =  null;
        let confeti = null;
        if (this.state.celebrate == 1) {
            // confeti =  <Confetti className="interval" active={this.state.confetti} config={ config }/>;
            winningPrize = <div className={this.state.showprize + " winnings"}><span>{t("TU AS GAGNÉ")}</span><p>{this.state.winningprize} FCFA</p></div>;
        }
        else if (this.state.celebrate == 2){
            winningPrize = <div className={this.state.showprize + " losing"}><span>
                <i className="fa fa-frown-o"></i><br/>
                {t("Essayez votre chance la prochaine fois")}</span></div>;
        }
        let loading = false;
        if (this.state.loadingscratch) {
            loading = <div className="loading-container overlay"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        if (this.props.ticketCount == 0) {
            button =
                <span className="nomore-tickets">{t("acheter")}</span>;
        }

        let extraRotate = '';
        if (this.state.winningExtra) {
            extraRotate = 'extra'
        }
        let loading1  = null;
        if (this.props.loading) {
            loading1 = <div className="loading-container" style={{marginTop: '30px'}}><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        return (
            <div className="game-subcontainer loadable-block playspin">
                <h2>{t("TOURNE TA CHANCE ET GAGNE JUSQU’À 1.000.000 FCFA")}</h2>
                {loading}
                <div className="mobile-container">
                <div className="only-mobile">
                    {winningPrize}
                </div>
                <div className="spin-win">
                    <div className="wheel-container">
                        <div className="weel-circle">
                            <img src={wheel} className={this.state.v + ' ' + extraRotate}  />
                        </div>
                        <div className="weel-base"></div>
                        {button}
                    </div>
                    <div className="non-mobile">
                        {winningPrize}
                    </div>
                </div>
                </div>
                {confeti}
                <div className="buy-ticket-container">
                    <div className="scratch-tickets">
                    <div className="ticket-price">
                            <label>{t("PRIX DU TICKET")}</label>
                            <span>{this.props.ticketPriceSW} FCFA</span>
                        </div>
                        <a className="main-btn" onClick={(event) => onBuy(event, 1)} href="#">{t("Acheter 1 ticket")}</a>
                        <a className="main-btn" onClick={(event) => onBuy(event, 5)} href="#">{t("Acheter 5 tickets")}</a>
                        <a className="main-btn" href="#" onClick={(event) => onBuy(event, 10)}>{t("Acheter 10 tickets")}</a>
                        {loading1}
                        {this.state.buyingms}
  
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        unplayedTickets: state.auth.unplayed_scratch,
        ticketPriceSW: state.auth.sw_ticketPrice,
        ticketMessageSW: state.auth.ticketMessageSW,
        statussw: state.auth.statusSW,
        loading: state.auth.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPlayTicket: (gameId, ticketId, serialNumber, scratchType) => dispatch(actions.playTicket(gameId, ticketId, serialNumber,scratchType)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(translate("translations")( SpinWinPlay ));
