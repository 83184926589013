
import React, { Component } from 'react';
import {  Col, Row } from 'bootstrap-4-react';
import Header from '../../../Components/NewHeader/Header';
import Footer from "../../../Components/Footer/Footer";
import SpinWinPlay from "./SpinWinPlay";
import SpinWinSideMenu from "./SpinWinSideMenu";
import HowToPlay from "./HowToPlay";
import Purchases from "./Purchases";
import Winnings from "./Winnings";
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import SpinWinLogin from "./SpinWinLogin";
import HowToWin from "./HowToWin";
import ReactGA from "react-ga";

class SpinWin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: '',
            active: "play",
            unplayedTickets: [],
            loading: false
        };
    }
    buyScratch = ( event, number ) => {
        event.preventDefault();
        this.setState({ loading: true });

        this.timeout = setInterval(() => {
            this.setState({ loading: false });
        }, 2000);
        this.props.onBuyScratch(this.props.gameId, number , 'sw', 5);

    };
    componentWillMount() {
        this.setState({
            buyingms: null
        })
    }
    componentDidMount() {
        window.scrollTo(0,0);
        ReactGA.pageview('/tourne-ta-chance');
        this.props.onGetScratch();
    }
    selectComponent=(event)=>{
        event.preventDefault();
        this.setState({component: event.target.name, active: event.target.name});
    }
    render() {

        let loggedin = false;
        if (this.props.isAuthenticated) {
            loggedin = true;
        }
        let toRender = <SpinWinLogin loggedIn={loggedin} onClick = {this.selectComponent}/>
        if (loggedin && this.props.gameId) {
            toRender = <SpinWinPlay onBuy={this.buyScratch} onClick = {this.selectComponent}  gameId={this.props.gameId} maxPrize={this.props.maxPrize} ticketCount={this.props.unplayedTickets} />;
        }
        let active= 'play';
        switch(this.state.component)
        {
            case "play":
                if (!loggedin) {
                    toRender =  <SpinWinLogin loggedIn={loggedin} />;
                } else {
                        if (this.props.gameId) {
                            toRender = <SpinWinPlay onBuy={this.buyScratch} onClick={this.selectComponent}
                                                    gameId={this.props.gameId} maxPrize={this.props.maxPrize}
                                                    ticketCount={this.props.unplayedTickets}/>;
                        }
                }
                active = "play";
                break;
            // case "purchase":
            //     if (!loggedin) {
            //         toRender =  <SpinWinLogin loggedIn={loggedin}/>;
            //     } else {
            //         toRender =<SpinWinBuy onClick={this.buyScratch} onCheck={this.selectComponent} gameId={this.props.gameId} maxPrize={this.props.maxPrize} loading={this.state.loading}  />;
            //     }
            //     active = "purchase";
            //     break;
            case "howtoplay":
                toRender = <HowToPlay/>;
                active = "howtoplay";
                break;
            case "purchases":
                toRender = <Purchases gameId={this.props.gameId}/>;
                active = "purchases";
                break;
            case "winnings":
                toRender = <Winnings gameId={this.props.gameId}/>;
                active = "winnings";
                break;
            case "howtowin":
                toRender = <HowToWin/>;
                active = "winnings";
                break;
        }
        return (
            <div className="mojabet-website">
                <Header/>
                <div className="game-container ">
                    <Row className="row-eq-height">
                        <Col col="lg-3 md-12 12"  className="sidemenu-container">
                            <SpinWinSideMenu unplayedTickets={this.props.unplayedTickets} balance={this.props.wallet} loggedIn={loggedin} onClick = {this.selectComponent} active={this.state.active}/>
                        </Col>
                        <Col col="lg-9 md-12 12" className="sw-container">
                            <div className="game scratch">
                            {toRender}
                            </div>
                        </Col>
                    </Row>
                </div>
                <Footer/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        wallet: state.auth.wallet,
        gameId: state.auth.swid,
        maxPrize: state.auth.sw_maxPrize,
        unplayedTickets: state.auth.unplayed_spinwin,
        loading: state.auth.loading,
        message: state.auth.message,
        status: state.auth.status,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() ),
        onGetScratch: () => dispatch(actions.getScratch(5)),
        onBuyScratch: (gameId, ticketNb, type, scratchType) =>  dispatch(actions.buyScratch(gameId, ticketNb, type,scratchType)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( SpinWin );
