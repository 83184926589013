import React, { Component } from 'react';
import * as PropTypes from "prop-types";
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import {Trans, translate} from "react-i18next";
class AfricanExplorerBuy extends Component {
    state = {
        buyingms: null
    }
    componentWillMount() {
        this.setState({
            buyingms: null
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.statusae == 1) {
            this.setState({
                buyingms: <div className="success" >
                    <i className="fa fa-check"></i>
                    <span>Tickets achetés avec succès</span>
                </div>
            })
        } else if (this.props.statusae == 0) {
            this.setState({
                buyingms: <div className="error" style={{marginTop: '35px'}}>
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>Une erreur s'est produite, veuillez réessayer plus tard</span>
                </div>
            })
        }
        else if (this.props.statusaf == 5) {
            this.setState({
                buyingms: <div className="error" style={{marginTop: '35px'}}>
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>Montant insuffisant dans votre portefeuille</span>
                </div>
            })
        }
    }
    componentWillUnmount() {
        this.setState({
            buyingms: null
        })
    }

    render() {
        const { t, i18n } = this.props;
        let {onClick, onCheck} = this.props;
        let loading  = null;
        if (this.props.loading) {
            loading = <div className="loading-container" style={{marginTop: '30px'}}><i className="fa fa-spinner fa-spin"></i> </div>;
        }

        return (
            <div className="game-subcontainer">
                <h2>{t("Trouvez votre animal!")}</h2>
                <h3>{t("Acheter des Tickets")}</h3>
                <div className="scratch-overall extra-margin">
                    <div className="scratch-tickets ">
                        <a className="main-btn" onClick={(event) => onClick(event, 1)} href="#">{t("Acheter 1 ticket")}</a>
                        <a className="main-btn" onClick={(event) => onClick(event, 5)} href="#">{t("Acheter 5 tickets")}</a>
                        <a className="main-btn" href="#" onClick={(event) => onClick(event, 10)}>{t("Acheter 10 tickets")}</a>
                        <button className="play-scratch" onClick={onCheck} name="play">{t("Jouer")}</button>
                        {loading}
                        {this.state.buyingms}
                        <div className="ticket-price">
                            <label>{t("PRIX DU TICKET")}</label>
                            <span>{this.props.ticketPrice} FCFA</span>
                        </div>
                    </div>
                    <div className="extra-scratch absolute ae"></div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        ticketMessageAE: state.auth.ticketMessageAE,
        loading: state.auth.loading,
        statusae: state.auth.statusAE,
        ticketPrice: state.auth.ae_ticketPrice
    };
};
export default connect( mapStateToProps )(translate("translations")( AfricanExplorerBuy ));