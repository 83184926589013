import React, { Component } from "react";
import Header from "../../Components/NewHeader/Header";
import Footer from "../../Components/Footer/Footer";
import Hero from "../../Components/Hero/Hero";
import TermsHero from "../../assets/images/termsHero.jpg";
import ReactGA from "react-ga";

class Privacy extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview("/privacy");
  }
  render() {
    return (
      <div className="Lonaci-website aboutus">
        <Header />
        <Hero background={TermsHero} />
        <section className="staticpages-content">
          <div className="container-fluid content-container">
            <div className="page-title-container">
              <h1 className="page-title">CONDITIONS GENERALES D’UTILISATION</h1>
            </div>
            <div className="page-text greenbg">
              
                        <p><span>Lonaci respecte votre vie privée. Nous nous engageons de déployer nos meilleurs efforts à protéger et à sécuriser vos données personnelles.</span></p>
                        <p><span>Cette politique de confidentialité explique les types de données personnelles que nous pouvons collecter, les finalités de la collecte, le traitement des données personnelles ainsi que vos droits en relation avec vos données personnelles.</span></p>
                        <p><span><b>1 - Les données personnelles que nous pouvons collecter</b></span></p>
                        <p><span>Lorsque vous jouez ou interagissez avec nous, nous pouvons collecter vos données personnelles.</span></p>
                        <p><span>La définition des données personnelles peut varier d’un pays à l’autre.</span></p>
                        <p><span>Pour assurer la protection de vos données personnelles, nous définissons ces informations comme toute information pouvant vous identifier directement ou indirectement.</span></p>
                        <p><span>Nous ne recueillons pas plus de données personnelles que ce qui est nécessaire pour vous fournir nos services et nos produits.</span></p>
                        <p><span>En fonction des circonstances et de notre relation commerciale et clientèle avec vous, nous pouvons collecter différentes catégories des données à caractère personnel y compris, mais sans s'y limiter, celles qui suivent :</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Données personnelles fournies par vous, selon les circonstances</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Informations de contact par exemple : noms, e-mail, numéros de téléphone</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Données personnelles collectées automatiquement lorsque vous utilisez notre Site, selon les circonstances :</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;– Type de navigateur</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;– Adresse IP</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;– Vos activités de navigation, telles que les pages web sur lesquelles vous avez cliqué</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;– Le système d’exploitation que vous utilisez</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;– Date et heure d’accès</span></p>
                        <p><span><b>2 -&nbsp;Données personnelles sensibles</b></span></p>
                        <p><span>Nous ne recueillons aucune donnée personnelle sensible.</span></p>
                        <p><span>La définition des données personnelles sensibles varie d’un pays à l’autre.</span></p>
                        <p><span>Lonaci considère comme des données personnelles sensibles toute information sur vous qui révèle votre origine raciale ou ethnique, vos opinions politiques, vos convictions religieuses ou philosophiques et votre appartenance à un syndicat, ainsi que des données concernant votre santé, vos informations génétiques et biométriques, votre vie sexuelle ou votre orientation sexuelle.</span></p>
                        <p><span><b>3 -&nbsp;Liens vers des tiers</b></span></p>
                        <p><span>Notre Site peut contenir des liens vers des sites web de tiers qui ne sont pas couverts par la présente politique de confidentialité. Nous n’acceptons aucune responsabilité pour les le contenu et les ressources relatives aux sites web de tiers.</span></p>
                        <p><span><b>4 -&nbsp;Pourquoi recueillons-nous vos données personnelles ?</b></span></p>
                        <p><span>Nous pouvons collecter vos données personnelles pour plusieurs raisons.</span></p>
                        <p><span>En général, nous collectons vos données personnelles pour :</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Vous fournir nos produits et services</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Communiquer avec vous à travers des plateformes indiquées : SMS, Appels, E-mail par notre service clientèle</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Communiquer avec vous à propos de modifications ou de mises à jour sur nos différents produits et services (jeux, répartition des gains, nos promos, nos termes et conditions, notre politique de confidentialité ou toute autre question pouvant vous intéresser)</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Vous permettre de naviguer sur notre Site et d’améliorer votre expérience utilisateur</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Réaliser des analyses de données</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; •	Profilage et statistiques de nos utilisateurs, ou tout autre personne ayant été en contact avec nous à travers nos jeux et nos outils de communications.</span></p>
                        <p><span><b>5 -&nbsp;Combien de temps conservons-nous vos données personnelles ?</b></span></p>
                        <p><span>Nous ne conservons pas vos données personnelles pour une durée indéterminée, plus longtemps que nécessaire.</span></p>
                        <p><span>Lors de la conservation de vos données personnelles, nous tenons compte des obligations légales et du but initial de la collecte. La durée de conservation de vos données personnelles peut varier en fonction du but de la collecte.</span></p>
                        <p><span><b>6 -&nbsp;Sur quelle base légale traitons-nous vos données personnelles ?</b></span></p>
                        <p><span>Le traitement de vos données personnelles peut être fondé sur les motifs suivants :</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; • Votre consentement, Veuillez noter que vous pouvez retirer votre consentement à tout moment en envoyant un courriel à serviceclient@lonaci-moja.ci ou support@lonaci-moja.ci si notre traitement est basé sur votre consentement avec un préavis raisonnable.</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; • La nécessité du traitement de données pour conclure et / ou exécuter un contrat entre vous et nous.</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; • Se conformer aux obligations légales ainsi qu’établir, exercer ou se défendre des actions en justice.</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; • Nos intérêts légitimes dans la mesure où ceux-ci ne priment pas sur vos intérêts ni vos libertés et droits fondamentaux.</span></p>
                        <p><span>&nbsp;&nbsp;&nbsp; • Exécuter une tâche dans l’intérêt de votre demande ( plaintes, suggestions et remarques ).</span></p>
                        <p><span>Les motifs juridiques pour le traitement de vos données personnelles varient en fonction des circonstances et du contexte de la collecte. Nous ne traitons pas vos données personnelles sans justification légale.</span></p>
                        <p><span><b>7 -&nbsp;Partageons-nous vos données personnelles ?</b></span></p>
                        <p><span>Non, vos informations ne seront d’aucunes manières divulguées, sauf lorsque vous nous le permettez par écrit.</span></p>
                        <p><span><b>8 -&nbsp;Vos gains</b></span></p>
                        <p><span>Lorsque vous gagnez, Lonaci s’exécute à transférer votre gain sur votre compte Mobile Money en passant par votre opérateur habituel, ce qui fait qu’aucune autre personne physique ou morale ne détiendra des informations sur le montant, l’heure ou d’autres informations concernant votre gain qu'aux seuls employés ou collaborateurs dont les fonctions justifient qu'ils en aient connaissance.</span></p>
                        <p><span>En cas des gains dépassant vos limites transactionnelles ou des gains au - delà des prévisions de votre opérateurs, Lonaci vous propose d’autres solutions ou moyens de transactions adaptés, sur lequel vous vous sentirez à l’aise sans attenter à vos droits et libertés.</span></p>
                        <p><span><b>9 -&nbsp;Sécurité de vos données personnelles</b></span></p>
                        <p><span>Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour assurer un niveau de sécurité approprié au risque encouru afin de protéger vos données personnelles contre une violation de la sécurité, de manière accidentelle ou illicite, entraînant la destruction, la perte, l’altération, la divulgation non autorisée ou l’accès illégal ou accidentel à vos données personnelles ainsi qu’à celles de vos gains quel que soit leurs montants.</span></p>
                        <p><span>Notre équipe est tenue de garder vos données personnelles confidentielles. Nous ne transférerons vos données personnelles à des tiers que décrit à la section 8 de la présente politique.</span></p>
                        <p><span><b>10 -&nbsp;Modifications et mises à jour de cette politique de confidentialité</b></span></p>
                        <p><span>Nous pouvons mettre à jour, changer, ajouter, supprimer ou modifier cette politique en publiant une nouvelle version sur notre Site.</span></p>
                        <p><span>Cette politique de confidentialité a été publiée en Janvier 2021. Le client ne reçoit aucune justification et notification préalablement à la suppression ou a la modification du contenu de Site.</span></p>
                        <p><span><b>11 -&nbsp;Comment nous contacter</b></span></p>
                        <p><span>Si vous avez des questions concernant le traitement de vos données personnelles ou souhaitez exercer votre droit prévu à la Section 8 de la présente Politique, veuillez soumettre votre demande via</span></p>
                        <p><span>Courriel :  <a  href={'mailto:serviceclient@lonaci-moja.ci?'} >serviceclient@lonaci-moja.ci</a> ou <a  href={'mailto:support@lonaci-moja.ci?'}>support@lonaci-moja.ci</a></span></p>
                 
                <div className="et_pb_bottom_inside_divider">&nbsp;</div>
              </div>
    
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Privacy;
