
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {translate} from "react-i18next";

class SportChanceLogin extends Component {
    render() {
        const {t} = this.props;
        return (
            <div className="game-subcontainer game-loggedout">
                <div className="sratch-logout">
                    {/* <h2>{t("Jouer et Gangner")}</h2> */}
                    <h4>DECOUVREZ TROIS (3) MONTANTS IDENTIQUES ET GAGNEZ JUSQU’À 100.000 FCFA</h4>
                    <Link className="login-game" to="/login">{t("CONNECTEZ-VOUS ET JOUEZ")}</Link>
                </div>
            </div>
        );

    }
}


export default translate("translations")(SportChanceLogin);