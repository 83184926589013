import React, { Component } from "react";
import axios from "axios";
import {translate} from "react-i18next";
import { scratchApi } from "../../../General/Api";
const FilterableTable = require('react-filterable-table');
class Winnings extends Component {
    async getPickWinnings() {
        let url= scratchApi(localStorage.getItem('token'),2,1,100,'0&isWinning=true'); //appended iswinning to the last item
        const res = await axios(url);
        return await res.data.tickets;
    }

    constructor(...args) {
        super(...args);
        this.state = {
            scratchdata: null,
            scratchLoading: false,
        };
    }
    componentDidMount() {
        this.setState({scratchLoading: true});
        (async () => {
            try {
                this.setState({scratchdata: await this.getPickWinnings()});
                this.setState({scratchLoading: false});
            } catch (e) {
                this.setState({scratchLoading: false});
            }
        })();
    }
    render(){
        const { t } = this.props;
        let fields = [
            { name: 'serialNumber', displayName: t("Référence"), inputFilterable: true, sortable: true },
            { name: 'purchaseDate', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
            { name: 'prize', displayName: t("Montant gagné"), inputFilterable: true, exactFilterable: true, sortable: true },
        ];
        let loading = false;
        if (this.state.scratchLoading) {
            loading = <div className="loading-container overlay"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        let tablerows = null;
        if (this.state.scratchdata) {
            tablerows = <FilterableTable
                namespace=""
                initialSort="id"
                data={this.state.scratchdata}
                fields={fields}
                noRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                noFilteredRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                pagerTitles={{ first: '<|', last: '>|' }}
            />;
        }
        return (
            <div className="purchases-table  loadable-block">
                {loading}
                {tablerows}
            </div>

        );
    }
}
export default translate("translations")(Winnings);
