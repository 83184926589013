
import React, { Component } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Header from '../../../Components/NewHeader/Header';
import Footer from "../../../Components/Footer/Footer";
import AfricanExplorerPlay from "./AfricanExplorerPlay";
import AfricanExplorerBuy from "./AfricanExplorerBuy";
import AfricanExplorerSideMenu from "./AfricanExplorerSideMenu";
import HowToPlay from "./HowToPlay";
import Purchases from "./Purchases";
import Winnings from "./Winnings";
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import AricanExplorerLogin from "./AricanExplorerLogin";
import axios from "axios";
import HowToWin from "./HowToWin";
import Wallet from "../../../Components/Wallet/Wallet";
import ReactGA from "react-ga";

class AfricanExplorer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: '',
            active: "play",
            unplayedTickets: [],
            loading: false
        };
        this.selectComponent = this.selectComponent.bind(this);
    }
    buyScratch = ( event, number ) => {
        this.setState({ loading: true });
        this.setState({ showmessage: true });
        event.preventDefault();
        this.timeout = setInterval(() => {
            this.setState({ loading: false });
        }, 2000);
        this.props.onBuyScratch(this.props.gameId, number, 'ae',6 );
        this.timeout = setInterval(() => {
            this.setState({showmessage: false});
        }, 6000);

    };
    componentDidMount() {
        ReactGA.pageview('/bingo-safari');
        window.scrollTo(0,0);
        this.props.onGetScratch(6);
    }

    componentWillUnmount() {
        this.setState({
            showmessage: false
        })
    }
    selectComponent(event){
        event.preventDefault();
        this.setState({component: event.target.name, active: event.target.name});
    }
    render() {

        let loggedin = false;
        if (this.props.isAuthenticated) {
            loggedin = true;
        }
        let toRender = <AricanExplorerLogin loggedIn={loggedin} onClick = {this.selectComponent}/>
        if (loggedin && this.props.gameId) {
            toRender = <AfricanExplorerPlay onBuy={this.buyScratch}  onClick = {this.selectComponent}  gameId={this.props.gameId} maxPrize={this.props.maxPrize} ticketCount={this.props.unplayedTickets} />;
        }
        let active= 'play';
        switch(this.state.component)
        {
            case "play":
                if (!loggedin) {
                    toRender =  <AricanExplorerLogin loggedIn={loggedin} />;
                } else {
                        // if (this.props.gameId) {
                            // toRender = <AfricanExplorerPlay onBuy={this.buyScratch}  onClick = {this.selectComponent}  gameId={this.props.gameId} maxPrize={this.props.maxPrize} ticketCount={this.props.unplayedTickets} />;
                        // }
                    toRender = <AfricanExplorerPlay onBuy={this.buyScratch}  onClick = {this.selectComponent}  gameId={this.props.gameId} maxPrize={this.props.maxPrize} ticketCount={this.props.unplayedTickets} />;
                }
                active = "play";
                break;
            // case "purchase":
            //     if (!loggedin) {
            //         toRender =  <AricanExplorerLogin loggedIn={loggedin}/>;
            //     } else {
            //         toRender =<AfricanExplorerBuy onClick={this.buyScratch} onCheck={this.selectComponent} gameId={this.props.gameId} maxPrize={this.props.maxPrize}  />;
            //     }
            //     active = "purchase";
            //     break;
            case "howtoplay":
                toRender = <HowToPlay/>;
                active = "howtoplay";
                break;
            case "purchases":
                toRender = <Purchases gameId={this.props.gameId}/>;
                active = "purchases";
                break;
            case "winnings":
                toRender = <Winnings gameId={this.props.gameId}/>;
                active = "winnings";
                break;
            case "howtowin":
                toRender = <HowToWin/>;
                active = "winnings";
                break;
        }
        return (
            <div className="mojabet-website">
                <Header/>
                <div className="game-container">
                    <Row className="row-eq-height">
                        <Col col="lg-3 md-12 12"  className="sidemenu-container">
                            <AfricanExplorerSideMenu unplayedTickets={this.props.unplayedTickets} balance={this.props.wallet} loggedIn={loggedin} onClick = {this.selectComponent} active={this.state.active}/>
                        </Col>
                        <Col col="lg-9 md-12 12" className="ae-container">
                            <div className="game scratch">
                            {toRender}
                            </div>
                        </Col>
                    </Row>
                </div>
                <Footer/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        wallet: state.auth.wallet,
        gameId: state.auth.aeid,
        maxPrize: state.auth.ae_maxPrize,
        unplayedTickets: state.auth.unplayed_africanExplorer,
        loading: state.auth.loading,

        statusae: state.auth.statusAE,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() ),
        onGetScratch: (gametype) => dispatch(actions.getScratch(gametype)),
        onBuyScratch: (gameId, ticketNb, type,scratchType) =>  dispatch(actions.buyScratch(gameId, ticketNb, type,scratchType)),
        // clearMessage: () => dispatch( actions.clearAllMessages())
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( AfricanExplorer );
