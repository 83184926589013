import React from "react";
import classes from "./Homepage.module.scss";

import PourLaCan from "../../assets/logosBackGround/routePourLaCanLogo.png";
import TumeDeChance from "../../assets/logosBackGround/tournetachance.png";
import PourLaCaf from "../../assets/images/caf/Caf-mainLogo.png";
import CafPrice from "../../assets/images/caf/Caf-price.png";
import CaftopTitle from "../../assets/images/caf/Caf-topTitle.png";
import moayeLogo from "../../assets/images/moayeLogo.png";
import moayePrice from "../../assets/images/moayePrice.png";
import moayeEgg from "../../assets/images/eggMoaye.png"
import BingoAlphabet from "../../assets/logosBackGround/bingoactive.png";
import SportChance from "../../assets/logosBackGround/sportactive.png";
import BonheurImage from "../../assets/logosBackGround/bonheurLogo.png";
import FruiteDeChance from "../../assets/logosBackGround/FruitDeChanceLogo.png";
import Pick3 from "../../assets/logosBackGround/pick3logo.png";
import BingoSafari from "../../assets/logosBackGround/bingosafari.png";
import MillionDouble from "../../assets/logosBackGround/doublemillionaireLogo.png";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/NewHeader/Header";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import hotNumber0 from "../../assets/hotNumbers/0.png";
import hotNumber1 from "../../assets/hotNumbers/1.png";
import hotNumber2 from "../../assets/hotNumbers/2.png";
import hotNumber3 from "../../assets/hotNumbers/3.png";
import hotNumber4 from "../../assets/hotNumbers/4.png";
import hotNumber5 from "../../assets/hotNumbers/5.png";
import hotNumber6 from "../../assets/hotNumbers/6.png";
import hotNumber7 from "../../assets/hotNumbers/7.png";
import hotNumber8 from "../../assets/hotNumbers/8.png";
import hotNumber9 from "../../assets/hotNumbers/9.png";
function Homepage() {
  const hotNumberObject = [
    { img: hotNumber0, text: "143 fois" },
    { img: hotNumber1, text: "139 fois" },
    { img: hotNumber2, text: "175 fois" },
    { img: hotNumber3, text: "134 fois" },
    { img: hotNumber4, text: "170 fois" },
    { img: hotNumber5, text: "142 fois" },
    { img: hotNumber6, text: "153 fois" },
    { img: hotNumber7, text: "162 fois" },
    { img: hotNumber8, text: "142 fois" },
    { img: hotNumber9, text: "140 fois" },
  ];
  return (
    <>
      <Header />
      <div className={classes.landingContainer}>
        <div className={classes.imagesContainer + " " + classes.top}>
          <Link
              to={"/double-millionaire"}
              className={`${classes.landingBackGround} ${classes.sponsored}  ${classes.millionDouble}`}
          >
            <img
                src={MillionDouble}
                alt="lonaci grattage "
                className={classes.doubleMillionairelogo}
            />
          </Link>
          {/* <Link
            to="/moaye-grattage"
            className={`${classes.landingBackGround} ${classes.sponsored}  ${classes.moayeGrattage}`}
          >
            <div className={classes.cafMiddleLogo}>
              <img
                src={moayeLogo}
                alt="lonaci grattage "
                className={classes.logoMoaye}
              />
            </div>
            <img
              src={moayePrice}
              alt="lonaci grattage "
              className={classes.priceMoaye}
            />
            <img
              src={moayeEgg}
              alt="lonaci grattage"
              className={classes.moayeEgg}
              />
          </Link> */}
           <Link
            to={"/pick3"}
            className={`${classes.landingBackGround} ${classes.pick3}`}
          >
            <img src={Pick3} alt="lonaci grattage " className={classes.logo} />
          </Link>
        </div>
        <div className={classes.imagesContainer + " " + classes.middle}>
          <Link
            to="/tourne-ta-chance"
            className={`${classes.landingBackGround} ${classes.tumeDeChance}`}
          >
            <img
              src={TumeDeChance}
              alt="lonaci grattage "
              className={classes.tumeDeChancelogo}
            />
          </Link>
          <Link
            to="/bingo-alphabet"
            className={`${classes.landingBackGround} ${classes.BingoAlphabet}`}
          >
            <img
              src={BingoAlphabet}
              alt="lonaci grattage "
              className={classes.logo}
            />
          </Link>
          <Link
            to="/sport-de-chance"
            className={`${classes.landingBackGround} ${classes.sportChance}`}
          >
            <img
              src={SportChance}
              alt="lonaci grattage "
              className={classes.logo}
            />
          </Link>
          <Link
            to="/7DuBonheur"
            className={`${classes.landingBackGround} ${classes.bonheurImage}`}
          >
            <img
              src={BonheurImage}
              alt="lonaci grattage "
              className={classes.logo + ' ' + classes.bonheurLogo}
            />
          </Link>
          <Link
            to={"/fruit-de-chance"}
            className={`${classes.landingBackGround} ${classes.fruiteDeChance}`}
          >
            <img
              src={FruiteDeChance}
              alt="lonaci grattage "
              className={classes.logo}
            />
          </Link>
          <Link
            to={"/bingo-safari"}
            className={`${classes.landingBackGround} ${classes.bingoSafari}`}
          >
            <img
              src={BingoSafari}
              alt="lonaci grattage "
              className={classes.bingoSafariLogo}
            />
            <div className={classes.bingo_Title}>bingo <br/>safari</div>
          </Link>
          
        </div>
        {/* <div className={classes.imagesContainer + " " + classes.bottom}>
          
        

        </div> */}
        <div className={classes.hotNumbers}>
          <span className={classes.hotNumbers__title}>
            nombres reccurents pick3
          </span>
          <div className={classes.hotNumbers__Wrapper}>
            {hotNumberObject.map((value, index) => {
              return (
                <div className={classes.hotNumbers__Number} key={index}>
                  <img
                    src={value.img}
                    alt="hotnumber"
                    className={classes.hotNumbers__image}
                  />
                  <span className={classes.hotNumbers__label}>
                    {value.text}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Homepage;
