import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import lonaciCiLogo from "../../assets/lonaci-online.png";
import classes from "./Header.module.scss";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {Button, Modal} from "react-bootstrap";
const { REACT_APP_REGISTRATION, REACT_APP_HOMEPAGE } = process.env;


function Header(props) {
  const [mobilemenu, setmobilemenu] = useState("closed");
  const [btnstate, setbtnstate] = useState("");
  const [menulist, setmenulist] = useState("hidden");
  const [dropDown, setDropDown] = useState("hidden");
  const [dropDownUser, setDropDownUser] = useState("hidden");
  const [totalAmount, setTotalAnount] = useState(0)
  const [winningAmmount, setWinningAmmount] = useState(0)
  const [sessionId, setSessionId] = useState(0)
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [sessionUrl, setSessionUrl] = useState(null);
  const [acctUrl, setAccturl] = useState(null);



  useEffect(()=> {
    setTotalAnount(props.wallet);
    setWinningAmmount(props.winningAmmount)
    setSessionId(props.session_id);

    if (props.isAuthenticated && localStorage.getItem("session_id")) {
      setSessionUrl( "?session=" + sessionId)
      setAccturl("?session=" + sessionId + "&page=my-account")
    }

  },[props.wallet,props.winningAmmount, props.session_id, props.isAuthenticated])

  const openmenu = () => {
    if (mobilemenu === "closed") {
      setmobilemenu("open");
      setbtnstate("open");
      setmenulist("visible");
    } else {
      setmobilemenu("closed");
      setbtnstate("closed");
      setmenulist("hidden");
    }
  };

  const submitLogin = async (event) => {
    event.preventDefault();
    await props.onAuth(username, password);
  };

  const openDropDown = () => {
    setDropDown((currentValue) =>
      currentValue === "visible" ? "hidden" : "visible"
    );
    setDropDownUser((currentValue) =>
      currentValue === "visible" ? "hidden" : "visible"
    );
  };

  const submitHandler = (event) => {
    event.preventDefault();
    // recaptchaRef.current.execute();
    props.onAuth(
        this.state.controls.username.value,
        this.state.controls.password.value
    );
  };
  const logout = (event) => {
    event.preventDefault();
    props.onLogout();
  };
  return (
    <header className={classes.header}>
      <Modal show={props.duplicateSession}>
        <Modal.Header>
          <Modal.Title>Alerte de sécurité</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vous avez une connexion active (Other null Spider, ) sur ce
          compte. Voulez-vous déconnecter la session existante?
        </Modal.Body>
        <Modal.Footer>
          <Button
              className="modal-non"
              variant="secondary"
              onClick={() => {
                props.updateSession(false);
              }}
          >
            Non
          </Button>
          <Button
              className="modal-oui"
              variant="primary"
              onClick={() => {
                props.updateSession(true);
              }}
          >
            Oui
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={classes.topHeader}>
        <Link to="/">
          <img src={logo} alt="lonaci grattage " className={classes.logo} />
        </Link>
        <a href="https://lonacionline.ci/" target="_blank">
          <img src={lonaciCiLogo} alt="lonaci ci" className={classes.logoLonacici} />
        </a>
        <div className={classes.infoMenu}>
          <div className={classes.burgermenuContainer} onClick={openmenu}>
            <i
              className={`${
                btnstate === "open" ? classes.openbtn : classes.closebtn
              }`}
            ></i>
            <i
              className={`${
                btnstate === "open" ? classes.openbtn : classes.closebtn
              }`}
            ></i>
            <i
              className={`${
                btnstate === "open" ? classes.openbtn : classes.closebtn
              }`}
            ></i>
          </div>
          {!props.isAuthenticated ?
              <div className={classes.circleUser} onClick={openDropDown}>
                <i className={classes.userIcon + ' fa fa-user'}></i>
              </div> : ''
          }
          {props.isAuthenticated ?
              <div className={classes.userDets}>
                {totalAmount ?
                    <span className={`${classes.userInfoGroup} ${classes.wallet}`}>
                      <span className={`${classes.userInfoImg} ${classes.dollar}`}>$</span>
                      <span>{totalAmount} FCFA</span>
                    </span>
                    :
                    <></>
                }
                {winningAmmount ?
                    <Link className={`${classes.userInfoGroup} ${classes.wallet}`}>
                      <span className={`${classes.userInfoImg} ${classes.dollar}`}>$</span>
                      <span>{winningAmmount} FCFA</span>
                    </Link>
                    :
                    <></>
                }
                {localStorage.getItem('msisdn') ?
                    <a className={classes.userInfoGroup}href={REACT_APP_HOMEPAGE + acctUrl}>
                      <i className={classes.userIcon + ' fa fa-user'}></i>
                      <span>{localStorage.getItem('msisdn')}</span>
                    </a>
                    :
                    <></>
                }
                <Link className={classes.userInfoGroup} to="/" onClick={() => props.logout()}>
                  <i className={classes.logout + " fa fa-times"}></i>
                  <span>Logout</span>
                </Link>

                <Link to="/account"><i className={`${classes.userIcon} ${classes.mobile} 'fa fa-user'`} /></Link>
              </div>
              :
              <div
                  className={`${
                      dropDownUser === "visible"
                          ? classes.dropDownVisible
                          : classes.dropDownHidden
                  }`}
              >
                <form onSubmit={submitLogin} className={classes.form}>

                  <input
                      className={classes.inputField}
                      type="text"
                      placeholder="PSEUDO"
                      onChange={(e) => setusername(e.target.value)}
                  />
                  <input
                      className={classes.inputField}
                      type="password"
                      placeholder="MOT DE PASSE"
                      onChange={e => setpassword(e.target.value)}
                  />
                  <button className={classes.btnHeader}>
                  SE CONNECTER
                    {props.loading ? <div className={classes.loading_container}>
                      <i className={"fa fa-spinner fa-spin"}></i>
                    </div> : null}
                  </button>
                  {props.error ? <span className={classes.error}>{props.error}</span>: null}
                  {/*<span className={classes.error}>this is an error</span>*/}
                </form>
                <div className={classes.linedropDown}/>
                <span  className={classes.dropDownText}>Si vous n'avez pas de compte <a href={REACT_APP_REGISTRATION} target="blank" className={classes.dropDownSinscrire}>S’INSCRIRE</a></span>
              </div>
          }

        </div>
      </div>
      <nav
        className={`${classes.menulist} ${
          menulist === "visible"
            ? classes.open
            : classes.close
        }`}
      >
        <ul className={classes.ul_list}>
          {/* <li className={classes.navigateTabs}>
            <Link to="" className={classes.list_word}>
              PAGE D’ACCUEIL
            </Link>
          </li> */}
          <div className={classes.lineSeperate} />
          <li className={classes.navigateTabs}>
            <Link to="/fruit-de-chance" className={classes.list_word}>
              FRUIT DE CHANCE<br/>
              {process.env.REACT_APP_FRUIT_TICKET_PRICE}
              
            </Link>
          </li>
          <div className={classes.lineSeperate} />

          <li className={classes.navigateTabs}>
            <Link to="/sport-de-chance" className={classes.list_word}>
              SPORT DE CHANCE<br/>
              {process.env.REACT_APP_SPORT_TICKET_PRICE}
            </Link>
          </li>
          <div className={classes.lineSeperate} />

          <li className={classes.navigateTabs}>
            <Link to="/tourne-ta-chance" className={classes.list_word}>
              TOURNE TA CHANCE<br/>
              {process.env.REACT_APP_SPORT_TICKET_PRICE}
            </Link>
          </li>
          <div className={classes.lineSeperate} />

          <li className={classes.navigateTabs}>
            <Link to="/bingo-safari" className={classes.list_word}>
              BINGO SAFARI<br/>
              {process.env.REACT_APP_SAFARI_TICKET_PRICE}
            </Link>
          </li>
          <div className={classes.lineSeperate} />

          <li className={classes.navigateTabs}>
            <Link to="/7DuBonheur" className={classes.list_word}>
              7 DU BONHEUR<br/>
              {process.env.REACT_APP_L7_TICKET_PRICE}
            </Link>
          </li>
          <div className={classes.lineSeperate} />

          <li className={classes.navigateTabs}>
            <Link to="/bingo-alphabet" className={classes.list_word}>
              BINGO ALPHABET<br/>
              {process.env.REACT_APP_BINGO_TICKET_PRICE}
            </Link>
          </li>
          <div className={classes.lineSeperate} />

          <li className={classes.navigateTabs}>
            <Link to="/pick3" className={classes.list_word}>
              PICK 3<br/>
              {process.env.REACT_APP_PICK3_TICKET_PRICE}
            </Link>
          </li>
          <div className={classes.lineSeperate} />

          <li className={classes.navigateTabs}>
            <Link to="/double-millionaire" className={classes.list_word}>
              DOUBLE MILLIONAIRE<br/>
              {process.env.REACT_APP_MILIONAIRE_TICKET_PRICE}
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    warning: state.auth.warning,
    redirectVerify: state.auth.redirectVerify,
    duplicateSession: state.auth.duplicateSession,
    wallet: state.auth.wallet,
    promoWallet: state.auth.promoWallet,
    session_id: state.auth.session_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onLogout: () => dispatch(actions.logout()),
    updateSession: async (renew) => {
      await dispatch(actions.renewSession(renew)); // the if(renew) exist in the function, just in case we had to do the call from somewhere else)
      if (renew &&  localStorage.getItem('account_code')) await dispatch(actions.stompConnect());

    },
    logout: () => dispatch(actions.endSession()),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate("translations")(Header));
