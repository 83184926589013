import React, { Component } from "react";
import {  Col, Row } from 'bootstrap-4-react';
import {translate} from "react-i18next";
import { instaphone } from "../../../Components/UI/Library";
class HowToPlay extends Component {
    constructor () {
        super();
        this.state = {
            playOptions: 'visible',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'hidden'
        }

    }
    openOnline=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'visible',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'hidden'
        })
    }
    openTigo=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'visible',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'hidden'
        })
    }
    openVodacom=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'visible',
            airtel: 'hidden',
            halotel: 'hidden'
        })
    }
    openAirtel=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'visible',
            halotel: 'hidden'
        })
    }
    openHalotel=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'hidden',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'visible'
        })
    }
    goBack=(event)=> {
        event.preventDefault();
        this.setState({
            playOptions: 'visible',
            online: 'hidden',
            tigo: 'hidden',
            vodacom: 'hidden',
            airtel: 'hidden',
            halotel: 'hidden'
        })
    }
    render() {
        const { t } = this.props;
        return (
            <div className="insta-how scartchhow">
                <div className={this.state.playOptions + " play-methods"}>
                    <div className="playonline">
                        {/* <h2>{t("jouer et gagner!")}</h2> */}
                        <ul>
                            <li><i>1</i><span>{t("ACHETEZ DES TICKETS")}</span></li>
                            <li><i>2</i><span>{t("GRATTEZ LE TICKET A L’AIDE DE LA SOURIS OU CLIQUEZ SUR ‘‘RÉVÉLER TOUT’’")}</span></li>
                            <li><i>3</i><span>{t("DECOUVREZ TROIS (3) FRUITS SIMILAIRES ET GAGNEZ UNE (1) FOIS LE MONTANT ASSOCIÉ ")} </span></li>
                        </ul>
                    </div>
                </div>
                <div className={"pick3 " + this.state.online}>
                <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {t("Back")}</a>
                    <div className=" online" className="play-method" >

                        <div className="playonline">
                            <h3>{t("Make your match, get fruity!")}</h3>
                            <ul>
                                <li><i>1</i><span>{t("Buy Basket (ticket)")}</span></li>
                                <li><i>2</i><span>{t("Reveal your cards (1 by 1 or All at once)")}</span></li>
                                <li><i>3</i><span>{t("Match 3 fruits and win!")} <br/> {t("If you didnt, buy another and try again")} :)</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"pick3 " + this.state.tigo}>
                <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {t("Back")}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7"  offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{t("Dial")} *150*01#</span></li>
                                    <li><i>2</i><span>{t("Select Lip Bills")}</span></li>
                                    <li><i>3</i><span>{t("Enter Company Number")} 123255</span></li>
                                    <li><i>4</i><span>{t("Enter Reference numbers")} 99</span></li>
                                    <li><i>5</i><span>{t("Enter Amount")} 99</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%"/>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={"pick3 " + this.state.vodacom}>
                <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {t("Back")}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7"  offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{t("Dial")} *150*00#</span></li>
                                    <li><i>2</i><span>{t("Pay for M-Pesa")}</span></li>
                                    <li><i>3</i><span>{t("Enter Company Number")} 123255</span></li>
                                    <li><i>4</i><span>{t("Enter Reference numbers")} 99</span></li>
                                    <li><i>5</i><span>{t("Enter amount")}</span></li>
                                    <li><i>6</i><span>{t("Enter Your Mpesa pin")}</span></li>
                                    <li><i>7</i><span>{t("Click 1 to confirm")}</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%"/>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={"pick3 " + this.state.airtel}>
                <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {t("Back")}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7"  offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{t("Dial")} *150*60#</span></li>
                                    <li><i>2</i><span>{t("Select pay Bills")}</span></li>
                                    <li><i>3</i><span>{t("Enter Company Number")} 123255</span></li>
                                    <li><i>4</i><span>{t("Enter amount")}</span></li>
                                    <li><i>5</i><span>{t("Enter Reference numbers")} 99</span></li>
                                    <li><i>6</i><span>{t("Enter Secret Number")}</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%"/>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={"pick3 " + this.state.halotel}>
                <a className="back" onClick={this.goBack} href="#"><i className="fa fa-chevron-left"></i> {t("Back")}</a>
                    <div className=" online" className="play-method" >
                        <Row>
                            <Col col="md-7"  offset="md-1">
                                <ul>
                                    <li><i>1</i><span>{t("Dial")} *150*88#</span></li>
                                    <li><i>2</i><span>{t("Pay for Halopesa")}</span></li>
                                    <li><i>3</i><span>{t("enter_cp_number_halotel")} 123255</span></li>
                                    <li><i>4</i><span>{t("Enter Reference numbers")} 99</span></li>
                                    <li><i>5</i><span>{t("Enter amount")}</span></li>
                                    <li><i>6</i><span>{t("Enter the secret code to verify")}</span></li>
                                </ul>
                            </Col>
                            <Col col="md-3">
                                <img src={instaphone} width="100%"/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
export default translate("translations")(HowToPlay);
