import React, { Component } from "react";
import axios from "axios";
import {translate} from "react-i18next";
import { pick3tokensApi } from "../../../General/Api";
const FilterableTable = require('react-filterable-table');

class Winnings extends Component {
    async getPickWinnings() {
        let url=pick3tokensApi(localStorage.getItem('token'),"0&isWinning=true");
        const res = await axios(url);
        return await res.data.tickets
    }

    constructor(...args) {
        super(...args);
        this.state = {
            pick3data: null,
            pick3Loading: false,
        };
    }

    componentDidMount() {
        this.setState({pick3Loading: true});
        if (!this.state.pick3data) {
            (async () => {
                try {
                    this.setState({pick3data: await this.getPickWinnings()});
                    this.setState({pick3Loading: false});
                } catch (e) {
                    this.setState({pick3Loading: false});
                }
            })();
        }
    }

    render() {
        let fields = [
            {name: 'ticketId', displayName: "Référence", inputFilterable: true, sortable: true},
            {name: 'date', displayName: "Date", inputFilterable: true, exactFilterable: true, sortable: true},
            {name: 'numbers', displayName: "NUMÉROS GAGNANTS", inputFilterable: true, exactFilterable: true, sortable: true},
            {
                name: 'winnings',
                displayName: "Montant gagné",
                inputFilterable: true,
                exactFilterable: true,
                sortable: true
            },
        ];
        let loading = false;
        if (this.state.pick3Loading) {
            loading = <div className="loading-container overlay"><i className="fa fa-spinner fa-spin"></i></div>;
        }
        let tablerows = null;
        if (this.state.pick3data) {
            tablerows = <FilterableTable
                namespace=""
                initialSort="id"
                data={this.state.pick3data}
                fields={fields}
                noRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                noFilteredRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                pagerTitles={{ first: '<|', last: '>|' }}
                pageSize={20}
            />;
        }
        return (
            <div className="purchases-table loadable-block">
                {loading}
                {tablerows}
            </div>
        );
    }
}
export default translate("translations")(Winnings);
