import React, { Component } from 'react';
import Header from '../../Components/NewHeader/Header';
import Footer from "../../Components/Footer/Footer";
import Hero from "../../Components/Hero/Hero";
import AboutHero from "../../assets/images/aboutus-hero.jpg";
import {translate, Trans} from "react-i18next";
import ReactGA from "react-ga";

class AboutUs extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
        ReactGA.pageview('/aboutus');
    }
    render() {
        const { t } = this.props;
        return (
            <div className="mojabet-website aboutus">
                <Header/>
                <Hero background={AboutHero} />
                <section className="staticpages-content">
                    <div className="container-fluid content-container">
                        <div className="page-title-container">
                            <h1 className="page-title">{t("à propos de nous")}</h1>
                        </div>
                        <div className={"aboutus-text greenbg"}>

                        <p>  La Loterie Nationale de Côte d'ivoire (LONACI), créée en 1970 est une Société Anonyme à Participation Financière Publique Majoritaire dont le capital social à ce jour est FCFA 400.000.000.</p>

                        <p>  Les objectifs visés par l'Etat de Côte d’Ivoire à LONACI dans le cadre de la concession exclusive de l’organisation et de l’exploitation des jeux du hasard en Côte d’Ivoire sont :</p>
                        <p style={{ paddingLeft: "2%"}}>  
                            <p>  -	La mise à la disposition de la population d'une structure lui permettant de satisfaire son envie de participer aux jeux de hasard et d'être récompensée dans des conditions optimales de transparence et de fiabilité ;</p>
                            <p>   -	La mobilisation de l'épargne publique volontaire en vue de la réalisation d'investissements dans les domaines de la santé, du social et de l'éducation.</p>
                        </p>
                        <p>   La signature de la marque, « ON A TOUS UNE BONNE RAISON DE JOUER », s’inscrit résolument dans cette démarche. </p>

                        <p>  Dans le cadre de son Plan Stratégique de Développement (PSD] en cours, LONACI s'est fixée une nouvelle vision : « Être une institution de jeux, performante et citoyenne, acteur de développement économique et social, au profit des populations ».</p>
                        <p>  LONACI tire sa force des valeurs qui composent sa charte managériale à savoir : le joueur, le professionnalisme, l'esprit d'équipe, la communication, l'éthique, l'anticipation et le partage. </p>

                        <p>  Certifiée au Jeu responsable niveau 2 de la WLA (World Lottery Association], LONACI est résolument engagée dans un processus de certification sur l'ensemble des périmètres de son activité que sont le management, la qualité, la sécurité et l'environnement.</p>
                        <p>  Depuis 2015, LONACI affiche un taux de croissance annuel supérieur à 20% ; ce qui lui a permis de réaliser un chiffre d'affaires de plus de 200 milliards en 2019.</p>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}

export default translate("translations")(AboutUs);