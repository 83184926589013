import React, { Component } from "react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { RemoveComma, SplitAmount } from "../../../Components/Functions";

class HowToWin extends Component {
  render() {
    const { t } = this.props;
    let howtowin = (
      <span className="game-not-available">
        LE JEU N'EST PAS DISPONIBLE AU MOMENT
      </span>
    );


    if (this.props.prizevalues) {
      this.props.prizevalues.sort((a, b) => a.value.localeCompare(b.value));
      howtowin = (
        <div className="how-win-spin">
          {this.props.prizevalues.map((value, index) => (
            <div key={index} className="how-win-details">
              <div className="icon-container">
                <img src={`${process.env.PUBLIC_URL}tourne-ta-chance/${value.value}.png`} />
              </div>
              <span> {RemoveComma(SplitAmount(value.prize)) } FCFA</span>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="game scratch spinwin">
        <div className="game-subcontainer">
          <h2>{t("TOURNE TA CHANCE ET GAGNE LE MONTANT ASSOCIÉ ")}</h2>
          {howtowin}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    prizevalues: state.auth.sw_prizeValues,
  };
};
export default connect(mapStateToProps)(translate("translations")(HowToWin));
