import React, { Component } from "react";
import { connect } from 'react-redux';
import {translate} from "react-i18next";
import { RemoveComma, SplitAmount } from "../../../Components/Functions";

class HowToWin extends Component {

    render() {
        let howtowin = <span className="game-not-available">LE JEU N'EST PAS DISPONIBLE AU MOMENT</span>;

        if (this.props.prizevalues) {
            howtowin = <ul>
        {this.props.prizevalues.map((value, index) => (
            <li key={index}>
              <i>{index + 1}</i>
              <img src={`${process.env.PUBLIC_URL}fruits/${value.value}.png`} />
              <img src={`${process.env.PUBLIC_URL}fruits/${value.value}.png`} />
              <img src={`${process.env.PUBLIC_URL}fruits/${value.value}.png`} />
              <span>{RemoveComma(SplitAmount(value.prize))} FCFA</span>
            </li>
          ))} </ul>;
        }
        return (
            <div className="insta-how scratch-how">
                <div className="pick3" style={{display: 'block'}}>
                    <div className=" online" className="play-method" >
                        <div className="scratch-howtowin">
                            {/* <h2>{t("Comment gagner")}</h2> */}
                            {howtowin}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        prizevalues: state.auth.af_prizeValues,
    };
};



export default connect( mapStateToProps )(translate("translations")( HowToWin ));