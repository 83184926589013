
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {translate} from "react-i18next";
import { l7icon } from '../../../Components/UI/Library';

class SlotLogin extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="game-subcontainer game-loggedout">
                <div className="sratch-logout">
                     {/* <h2>{t("jouer et gagner!")}</h2> */}
                     <h4>TROUVEZ TROIS (3) FORMES IDENTIQUES ET GAGNEZ JUSQU’À 7.000.000 FCFA</h4>
                    <Link className="login-game" to="/login">{t("CONNECTEZ-VOUS ET JOUEZ")}</Link>
                    <div className="l7-icon">
                        <img src={l7icon} />
                    </div>
                </div>
            </div>

        );

    }
}

export default translate("translations")(SlotLogin);