import React, { Component } from 'react';
import { Route,Redirect , withRouter} from 'react-router-dom';
import Router from 'react-router-dom/BrowserRouter';
import { connect } from 'react-redux';
import Layout from './Components/Layout';
import './App.css';
import './games.css';
import Homepage from "./Containers/Homepage/Homapege";
import Pick3 from "./Containers/Games/Pick3/Pick3";
import Scratch from "./Containers/Games/Scratch/Scratch";
import SportChance from "./Containers/Games/SportChance/SportChance";
import DoubleMillionaire from "./Containers/doubleMillionaire/doubleMillionaire";
import Bingo from "./Containers/Games/Bingo/Bingo";
import AfricanExplorer from "./Containers/Games/AfricanExplorer/AfricanExplorer";
import SpinWin from "./Containers/Games/SpinWin/SpinWin";
import * as actions from './store/actions/index';
import Slot from "./Containers/Games/Slot/Slot";
import Login from "./Containers/Login/Login";
import ReactGA from 'react-ga';
import VerifyPhone from "./Containers/Register/VerfiyPhone";
import AboutUs from "./Containers/AboutUs/AboutUs";
import ContactUs from "./Containers/ContactUs/ContactUs";
import Privacy from "./Containers/Privacy/Privacy";
import CanGrattage from './Containers/CanGrattage/CanGrattage';
import CafGrattage from './Containers/CafGrattage/CafGrattage';
import Terms from "./Containers/Terms/Terms";
import axios from "axios";
import { userConfirmChangeMailApi, userConfirmMailApi, unlockAccountApi } from './General/Api';
import { JeuResponsable } from './Containers/JeuResponsable/JeuResponsable';
import packageJson from "../package.json";
import MoayeGrattage from './Containers/MoayeGrattage/MoayeGrattage';

class App extends Component {
    

   async componentDidMount () {
        await this.caching();
        this.props.onTryAutoSignup();
        ReactGA.initialize('UA-187061746-1');
        ReactGA.pageview('/');
        const queryString = require('query-string');
        const parsed = queryString.parse(this.props.location.search);

        //if redirect with a session from another website
        if(parsed.session)
        {
            await this.props.externalAuth(parsed.session);
            this.props.history.push('/')
        }
        if (parsed.token) {
            const token = parsed.token;
            if (parsed.emailconfirmed == 1) {
                let url = userConfirmMailApi(token);
                axios.patch(url)
                    .then(response => {
                        console.log('confirm', response)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            if (parsed.changeemailconfiremed == 1) {
                let url = userConfirmChangeMailApi(token);
                axios.patch(url)
                    .then(response => {
                        console.log('confirm', response)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            if (parsed.unlockemail == 1) {
                let url = unlockAccountApi(token);
                axios.patch(url)
                    .then(response => {
                        console.log('confirm', response)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }

    }

    caching = () => {
        let version = localStorage.getItem("version");
    
        if (version != packageJson.version) {
          if ("caches" in window) {
            caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach((name) => {
                caches.delete(name);
              });
            });
    
            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
          }
          localStorage.clear();
    
          localStorage.setItem("version", packageJson.version);
        }
      };


    render() {
        console.log("app props",this.props)
        let routes  = (
            <Router>
                <Layout>
                    <Route exact  path="/login" component={Login}/>
                    {/* <Route exact  path="/register" component={Register}/> */}
                    <Route exact  path="/" component={Homepage}/>
                    <Route exact  path="/pick3" component={Pick3}/>
                    <Route exact  path="/fruit-de-chance" component={Scratch}/>
                    <Route exact  path="/sport-de-chance" component={SportChance}/>
                    <Route exact  path="/double-millionaire" component={DoubleMillionaire}/>
                    <Route exact  path="/bingo-alphabet" component={Bingo}/>
                    <Route exact  path="/bingo-safari" component={AfricanExplorer}/>
                    <Route exact  path="/tourne-ta-chance" component={SpinWin}/>
                    <Route exact  path="/7DuBonheur" component={Slot}/>
                    {/* <Route exact  path="/caf-grattage" component={CafGrattage}/> */}
                    {/* <Route exact  path="/moaye-grattage" component={MoayeGrattage}/> */}
                    <Route exact  path="/verify" component={VerifyPhone}/>
                    {/* <Route exact  path="/forgotpassword" component={ForgotPassword}/> */}
                    {/* <Route exact  path="/account" component={AccountDashboard} /> */}
                    {/* <Route exact  path="/changeMsisdn" component={ChangeMsisdn}/> */}
                    {/* <Route exact  path="/resetpassword" component={ResetPassword}/> */}
                    {/* <Route exact  path="/changepassword" component={ChangePassword}/> */}
                    <Route        path="/contactus" component={ContactUs}/>
                    {/* <Route exact  path="/news" component={NewsListing}/> */}
                    {/* <Route exact  path="/news/:id" component={NewsArticle}/> */}
                    <Route        path="/aboutus" component={AboutUs}/>
                    <Route exact  path="/terms" component={Terms}/>
                    <Route exact  path="/privacy" component={Privacy}/>
                    <Route exact  path="/jeu-responsable" component={JeuResponsable}/>
                    {/* <Route exact  path="/jackpot" component={JackpotListing}/>
                    <Route exact  path="/jackpot/:id" component={JackpotArticle}/> */}
                </Layout>
            </Router>
        );
        if ( this.props.isAuthenticated ) {

            routes  = (
                <Router>
                    <Layout>
                        <Route exact  path="/sport-de-chance" component={SportChance}/>
                        <Route exact  path="/double-millionaire" component={DoubleMillionaire}/>
                        <Route exact  path="/" component={Homepage}/>
                        <Route exact  path="/pick3" component={Pick3}/>
                        <Route exact  path="/bingo-alphabet" component={Bingo}/>
                        <Route exact  path="/fruit-de-chance" component={Scratch}/>
                        <Route        path="/contactus" component={ContactUs}/>
                        <Route exact  path="/bingo-safari" component={AfricanExplorer}/>
                        <Route exact  path="/tourne-ta-chance" component={SpinWin}/>
                        <Route exact  path="/7DuBonheur" component={Slot}/>
                        <Route        path="/aboutus" component={AboutUs}/>
                        {/* <Route exact  path="/news" component={NewsListing}/> */}
                        {/* <Route exact  path="/news/:id" component={NewsArticle}/> */}
                        {/* <Route exact  path="/register" component={Register}/> */}
                        {/* <Route exact  path="/jackpot" component={JackpotListing}/>
                        <Route exact  path="/jackpot/:id" component={JackpotArticle}/> */}
                        {/* <Route exact  path="/account" component={AccountDashboard}/> */}
                        {/* <Route exact  path="/account/:section" component={AccountDashboard}/> */}
                        {/* <Route exact  path="/account/:section/:payment" component={AccountDashboard}/> */}
                        {/* <Route exact  path="/changeMsisdn" component={ChangeMsisdn}/> */}
                        {/* <Route exact  path="/resetpassword" component={ResetPassword}/> */}
                        {/* <Route exact  path="/forgotpassword" component={ForgotPassword}/> */}
                        {/* <Route exact  path="/caf-grattage" component={CafGrattage}/> */}
                        {/* <Route exact  path="/moaye-grattage" component={MoayeGrattage}/> */}
                        <Route exact  path="/verify" component={VerifyPhone}/>
                        <Route exact  path="/privacy" component={Privacy}/>
                        <Route exact  path="/terms" component={Terms}/>
                        <Route exact  path="/jeu-responsable" component={JeuResponsable}/>
                        <Route exact  path="/login" component={Login}/>
                    </Layout>
                          
                    </Router>
                    );
                }
        
                return (
                    <div>
                        {routes}
                    </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() ),
        externalAuth:(session)=>dispatch(actions.externalAuth(session))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( props => <App {...props} /> ) );
