const lucky7Logo = require("../../assets/images/bonheur.png");
const slot = require("../../assets/images/slot-animated-header.png");
const winbig = require("../../assets/images/winbig-animated-header.png");
const pick3Pple = require("../../assets/images/pick3-pple-animated-header.png");
const pick3Logo = require("../../assets/images/pick3-logo-animated-header.png");
const africanLogo = require("../../assets/images/bingo-safari.png");
const africanMan = require("../../assets/images/man-animal-african-header.png");
const fruit = require("../../assets/images/fruit-logo.png");
const newfruit = require("../../assets/images/new-fruit-logo.png");
const pick3LogoStatic = require("../../assets/images/pick3-logo.png");
const sportLogo = require("../../assets/images/sport-logo.png");
const spinwinLogo = require("../../assets/images/tourne-ta-chance.png");
const logoActive = require("../../assets/images/logo.png");
const logo = require("../../assets/images/logo-white.png");
const ageRestriction = require("../../assets/images/age-restriction.png");
const operator = require("../../assets/images/mobile-op.png");
const instaphone = require("../../assets/images/insta-phone.png");
const africanExplorer = require("../../assets/images/african-explorer-logo.png");
const unscratched = require("../../assets/images/ae-unscratched.png");
const brush = require("../../assets/images/brush.png");
const bingoUnscratched = require("../../assets/images/bingo-unscratched.jpg");
const bingoLogo = require("../../assets/images/header-icons/bingo-active.png");
const dmlogo = require("../../assets/images/dm-logo.png");
const burjArab = require("../../assets/images/burj-arab.png");
const roue = require("../../assets/images/roue.png");
const doubleMillUnscratched = require("../../assets/images/doublemillionaire-unscratched.png");
const afUnscratched = require("../../assets/images/af-unscratche.jpg");
const scratchAnanas = require("../../assets/images/scratch-ananas.png");
const newsportlogo = require("../../assets/images/new-sport-logo.png");
const bingoAfricanUnscratched = require("../../assets/images/bingo safari unscratched.png");
const l7icon = require("../../assets/images/7.png");
const diamond = require("../../assets/images/diamond.png");
const dollar = require("../../assets/images/dollar.png");
const coinset = require("../../assets/images/coinSet.png");
const dancingman = require("../../assets/images/dancingMan.png");
const diceSet = require("../../assets/images/diceSet.png");
const wheel = require("../../assets/images/wheel.png");
const sportUnscratched = require("../../assets/images/sport-unscratched.jpg");
const bingoticketprice = require("../../assets/images/bingo-ticket-price.png");
const football = require("../../assets/images/football.png");
const sportLogoActive = require('../../assets/images/header-icons/sport-active.png');
const planeMobile= require('../../assets/images/plane-mobile.png');
const planeDesktop= require('../../assets/images/plane-Desktop.png');
const parier18Logo= require('../../assets/images/footer/parier18.png');
// const ubaLogo= require('../../assets/images/footer/uba.png');
const moovMoneyLogo= require('../../assets/images/footer/moov money.png');
const mtnLogo= require('../../assets/images/footer/mtn.png');
const orangeMoneyLogo= require('../../assets/images/footer/orange money.png');
const priceTag= require('../../assets/images/price-tag.png');
const LogoLonaciNew= require('../../assets/images/Logo lonaci new.png');




export {
  lucky7Logo,slot,winbig,pick3Pple,pick3Logo,africanLogo,africanMan,fruit, newfruit,scratchAnanas,pick3LogoStatic,
  sportLogo,spinwinLogo,logo,ageRestriction,operator,instaphone,africanExplorer,unscratched,brush,
  bingoUnscratched,bingoLogo,dmlogo,burjArab,roue,doubleMillUnscratched,
  afUnscratched,l7icon,diamond,dollar,coinset,dancingman,diceSet,wheel,
  sportUnscratched,sportLogoActive,logoActive,planeMobile,planeDesktop, bingoAfricanUnscratched,
  parier18Logo,moovMoneyLogo,mtnLogo,orangeMoneyLogo, newsportlogo, football, bingoticketprice, priceTag,
  LogoLonaciNew
};
