import React, { Component } from "react";
import axios from "axios";
import {translate} from "react-i18next";
import { scratchApi } from "../../../General/Api";
const FilterableTable = require('react-filterable-table');

const url=(token)=>scratchApi(token,8,1,100,0);

class Purchases extends Component {
    async getPickWinnings() {
        const res = await axios(url(localStorage.getItem('token')));
        return await res.data.tickets;
    }

    constructor(...args) {
        super(...args);
        this.state = {
            scratchdata: null,
            scratchLoading: false,
        };
    }
    componentDidMount() {
        this.setState({
            scratchLoading: true
        });
        axios.get(url(localStorage.getItem('token')))
            .then(response => {
                if(response.data.status === 1) {
                    this.setState({
                        scratchLoading: false,
                        scratchdata: response.data.tickets,
                    });
                }
                else {
                    this.setState({
                        scratchLoading: false
                    })
                }
            })
            .catch(function (error) {
                // this.setState({
                //     loadingscratch: false
                // })
            });
    }
    render(){
        const { t } = this.props;
        let fields = [
            { name: 'serialNumber', displayName: t("Référence "), inputFilterable: true, sortable: true },
            { name: 'purchaseDate', displayName: t("Date "), inputFilterable: true, exactFilterable: true, sortable: true },
            { name: 'prize', displayName: t("Gains "), inputFilterable: true, exactFilterable: true, sortable: true },
        ];
        let loading = false;
        if (this.state.scratchLoading) {
            loading = <div className="loading-container overlay"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        let tablerows = null;
        if (this.state.scratchdata) {
            tablerows = <FilterableTable
                namespace=""
                initialSort="id"
                data={this.state.scratchdata}
                fields={fields}
                noRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                noFilteredRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                pagerTitles={{ first: '<|', last: '>|' }}
            />;

        }
        return (
            <div className="purchases-table  loadable-block">
                {loading}
                {tablerows}
            </div>

        );
    }
}
export default translate("translations")(Purchases);
