
export const AUTH_START = 'AUTH_START';
export const AUTH_START2 = 'AUTH_START2';
export const VER_START = 'VER_START';
export const VER_FAIL = 'VER_FAIL';
export const REG_START = 'REG_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_FAIL2 = 'AUTH_FAIL2';
export const AUTH_REG_FAIL = 'AUTH_REG_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY_MSISDN = 'AUTH_VERIFY_MSISDN';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const REDIRECT_VERIFY = 'REDIRECT_VERIFY';
export const DISPLAY_WARNING = 'DISPLAY_WARNING';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_AF = 'UPDATE_MESSAGE_AF';
export const UPDATE_MESSAGE_AE = 'UPDATE_MESSAGE_AE';
export const UPDATE_MESSAGE_BR = 'UPDATE_MESSAGE_BR';
export const UPDATE_MESSAGE_SW = 'UPDATE_MESSAGE_SW';
export const UPDATE_MESSAGE_BA = 'UPDATE_MESSAGE_BA';
export const UPDATE_MESSAGE_SC = 'UPDATE_MESSAGE_SC';
export const UPDATE_PICK_MESSAGE = 'UPDATE_PICK_MESSAGE';
export const SET_SCRATCH = 'SET_SCRATCH';
export const SET_BINGO = 'SET_BINGO';
export const SET_SPIN_WIN = 'SET_SPIN_WIN';
export const SET_LUCKY7 = 'SET_LUCKY7';
export const SET_UNPLAYED = 'SET_UNPLAYED';
export const SET_AFRICAN_EXPLORER = 'SET_AFRICAN_EXPLORER';
export const SEND_PLAY_NOT = 'SEND_PLAY_NOT';
export const SET_BROKEN_RECORD = 'SET_BROKEN_RECORD';
export const SET_UNPLAYED_SPIN_WIN = 'SET_UNPLAYED_SPIN_WIN';
export const SET_UNPLAYED_AFRICAN_EXPLORER = 'SET_UNPLAYED_AFRICAN_EXPLORER';
export const SET_UNPLAYED_BROKEN_RECORD = 'SET_UNPLAYED_BROKEN_RECORD';
export const UPDATE_MESSAGE_L7 = 'UPDATE_MESSAGE_L7';
export const SET_UNPLAYED_LUCKY7 = 'SET_UNPLAYED_LUCKY7';
export const SET_UNPLAYED_BINGO = 'SET_UNPLAYED_BINGO';
export const SET_UNPLAYED_SPORT = 'SET_UNPLAYED_SPORT';
export const SET_SPORT = 'SET_SPORT';
export const GET_DUPLICATE_SESSION = 'GET_DUPLICATE_SESSION';
export const EXT_AUTH = 'EXT_AUTH';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const STOMP_CONNECTED = 'STOMP_CONNECTED';
export const SET_USER_MSISDN = 'SET_USER_MSISDN';
