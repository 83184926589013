import React, { Component } from 'react';
import ReactGA from "react-ga";
import axios from "axios";
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import { translate} from "react-i18next";
import "./slot.css";
import { scratchApi } from '../../../General/Api';

const config = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: 500,
    dragFriction: 0.1,
    duration:3000,
    delay: 0,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};
class SlotPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotate: null,
            ticketId: null,
            serialNumber: null,
            ticketValues: [],
            winning: false,
            celerate: false,
            prize: null,
            loadingscratch: false,
            firstAttempt: 0,
            winningExtra: false,
            celebrate: null,
            disable: '',
            buyingms: null
        };


    }
    componentWillMount () {
        // let url =  '/gameapi/tokens/'+ localStorage.getItem('token') +'/scratchgames/'+ this.props.gameId  +'/scratchticket';
        // this.setState({
        //     loadingscratch: true,
        // });
        // axios.get(url)
        //     .then(response => {
        //         console.log(response);
        //         if(response.data.status == 1) {
        //             console.log('h')
        //             this.setState({
        //                 ticketId: response.data.scratchTicket.ticketId,
        //                 serialNumber: response.data.scratchTicket.serialNumber,
        //                 ticketValues: response.data.scratchTicket.ticketValues,
        //                 loadingscratch: false,
        //                 winningValue: response.data.scratchTicket.winningValue,
        //                 winningprize: response.data.scratchTicket.prize
        //             });
        //             console.log(this.state.ticketId);
        //         }
        //         else {
        //             this.setState({
        //                 loadingscratch: false,
        //             });
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }
    reset=()=> {
        this.timeout = setInterval(() => {
            this.setState({ v: 'reset', celebrate: null });
        }, 6000);
    }

    spinWheel=(event)=> {
        event.preventDefault();
        ReactGA.event({
            category: 'Games',
            action: 'Play 7 Du Bonheur'
        });

        this.setState({
            celebrate: null,
            showprize: '',
            disable: 'disable'
        });
        this.getTicket();

        this.setState({
            v: this.state.winningValue,
            firstAttempt: 1,
        });

        setTimeout(() => this.setState({ disable: '' }), 7000);

    }
    // componentWillReceiveProps(nextProps, nextContext) {
    //     this.getTicket();
    // }

     getTicket=()=> {
        console.log('ger')
        var losingClasses= new Array("losing-1","losing-2","losing-3", "losing");
        var random = losingClasses[Math.floor(Math.random() * losingClasses.length)];
        let url =  scratchApi(localStorage.getItem('token'),4,0,1,0); 
        axios.get(url)
        .then(response => {
            console.log('get ticket', response);
            this.setState({
                ticketId: response.data.tickets[0].id,
                serialNumber: response.data.tickets[0].serialNumber,
                ticketValues: response.data.tickets[0].ticketValues,
                winningValue: response.data.tickets[0].winningValue,
                winningprize: response.data.tickets[0].prize,
                button: '',
                showprize:'clicked',
                buyingms: null

            });
            this.props.onPlayTicket(this.props.gameId, this.state.ticketId, this.state.serialNumber, 4);
            if (response.data.tickets[0].winningValue == this.state.winningValue) {
                this.setState({
                    winningExtra: !this.state.winningExtra,
                })
            }
            if (this.state.winningValue != 'losing') {
                this.setState({
                    v: response.data.tickets[0].winningValue,
                    celebrate: 1,
                    prize: this.state.winningprize,

                })
            } else {
                this.setState({
                    v: random,
                    celebrate: 2,
                    prize: null,
                })

            }

        })
        .catch(function (error) {
            console.log(error);
        });
    }
    // componentWillMount() {
    //     this.setState({
    //         buyingms: null
    //     })
    // }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('nextprops', nextProps)
        if (this.props.statusL7 == 1) {
            this.setState({
                buyingms: <div className="success" >
                    <i className="fa fa-check"></i>
                    <span>Tickets achetés avec succès</span>
                </div>
            })
        } else if (this.props.statusL7 == 0) {
            this.setState({
                buyingms: <div className="error" style={{marginTop: '35px'}}>
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>Une erreur s'est produite, veuillez réessayer plus tard</span>
                </div>
            })
        }
        else if (this.props.statusL7 == 5) {
            this.setState({
                buyingms: <div className="error" style={{marginTop: '35px'}}>
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>Montant insuffisant dans votre portefeuille</span>
                </div>
            })
        }
        if (this.props.statusL7 == 99) {
            this.setState({
                buyingms:null
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            buyingms: null
        })
    }
    render() {
        const { t } = this.props;
        console.log('ticcccck', this.state.ticketId);
        let button = <a className={this.state.disable + " play-lucky7"} href="#"  name="play" onClick={this.spinWheel} >{t("Jouer")}</a>;
        let { onBuy} = this.props;
        let winningPrize =  null;
        if (this.state.celebrate == 1) {
            winningPrize = <div className={this.state.showprize + " winnings"}><span><i className="fa fa-smile-o"></i> {t("vous avez gagné")}</span><p>{this.state.winningprize} FCFA</p></div>;
        }
        else if (this.state.celebrate == 2){
            winningPrize = <div className={this.state.showprize + " losing"}><span><i className="fa fa-frown-o "></i>{t("Essayez votre chance la prochaine fois")}</span></div>;
        }
        let loading = false;
        if (this.state.loadingscratch) {
            loading = <div className="loading-container overlay"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        if (this.props.ticketCount == 0) {
            button =
                <span className="nomore-tickets">{t("acheter")}</span>;
        }

        let extraRotate = '';
        if (this.state.winningExtra) {
            extraRotate = 'extra'
        }
        let loading1  = null;
        if (this.props.loading) {
            loading1 = <div className="loading-container" style={{marginTop: '30px'}}><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        return (
            <div className="game-subcontainer loadable-block playspin">
                {/* <h2>{t("jouer et gagner!")}</h2> */}
                <h4>{t("TROUVEZ TROIS (3) FORMES IDENTIQUES ET GAGNEZ JUSQU’À 7.000.000 FCFA")}</h4>
                {loading}
                <div className="only-mobile tablet">
                    {winningPrize}
                </div>
                <div className="slot-container">
                <div className="slot-machine">
                    <div className={this.state.v + ' slot-1 ' + extraRotate}></div>
                    <div className={this.state.v + ' slot-2 ' + extraRotate}></div>
                    <div className={this.state.v + ' slot-3 ' + extraRotate}></div>
                </div>
                {button}
                <div className="non-mobile non-tablet">
                    {winningPrize}
                </div>
                </div>
                <div className="buy-ticket-container">
                    <div className="scratch-tickets">
                    <div className="ticket-price">
                            <label>{t("PRIX DU TICKET")}</label>
                            <span>{this.props.ticketPriceL7} FCFA</span>
                        </div>
                        <a className="main-btn" onClick={(event) => onBuy(event, 1)} href="#">{t("Acheter 1 ticket")}</a>
                        <a className="main-btn" onClick={(event) => onBuy(event, 5)} href="#">{t("Acheter 5 tickets")}</a>
                        <a className="main-btn" href="#" onClick={(event) => onBuy(event, 10)}>{t("Acheter 10 tickets")}</a>
                        {loading1}
                        {this.state.buyingms}

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        unplayedTickets: state.auth.unplayed_scratch,
        ticketPriceL7: state.auth.l7_ticketPrice,
        ticketMessageL7: state.auth.ticketMessageL7,
        statusL7: state.auth.statusL7,
        loading: state.auth.loading,
        l7play: state.auth.l7play,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPlayTicket: (gameId, ticketId, serialNumber, scratchType) => dispatch(actions.playTicket(gameId, ticketId, serialNumber,scratchType)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(translate("translations")( SlotPlay ));