import React, { Component } from "react";
import Confetti from "react-confetti";
import axios from "axios";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import {  translate } from "react-i18next";
import ReactGA from "react-ga";
import { scratchApi } from "../../../General/Api";
import {bingoUnscratched, brush, bingoLogo, bingoticketprice} from "../../../Components/UI/Library";

const noop = (o) => o;

const config = {
  angle: 90,
  spread: 45,
  startVelocity: 45,
  elementCount: 500,
  dragFriction: 0.1,
  duration: 3000,
  delay: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  buyingms: null, cardColor: ''
};

const url = (token)=> scratchApi(token,7,0,1000,0);

class BingoPlay extends Component {
  isFlipped = false;
  static defaultProps = {
    onReveal: noop,
  };
  constructor(props) {
    super(props);
    this.canvaContainer = React.createRef();
    this.imageref = React.createRef();
    this.state = {
      animal0: false,
      animal1: false,
      animal2: false,
      animal3: false,
      animal4: false,
      animal5: false,
      animal6: false,
      animal7: false,
      animal8: false,
      animal9: false,
      confetti: false,
      ticketId: null,
      serialNumber: null,
      ticketValues: [],
      winning: false,
      celerate: false,
      prize: null,
      loadingscratch: false,
      freload: false,
      imagesrc: null,
      loaded: false,
      hideCanvas: false,
      finished: false,
      scratchAll: false,
      playButton: false,
      lastTicketPlayed: false,
    };

    this.isDrawing = false;
    this.lastPoint = null;
  }
  generateRandomColor=()=> {
      var colorClasses= new Array("color-A","color-B","color-C", "color-D", "color-E","color-F","color-G","color-H","color-O","color-M","color-S","color-Z");
      var random = colorClasses[Math.floor(Math.random() * colorClasses.length)];
      this.setState({
          cardColor: random
      })
  }
  componentDidMount() {
      this.generateRandomColor();
    const canvas = this.canvas;
    canvas.width = canvas.parentElement.offsetWidth;
    canvas.height = canvas.parentElement.offsetHeight;

    canvas.addEventListener("mousedown", this.touchStart);
    canvas.addEventListener("touchstart", this.touchStart);
    canvas.addEventListener("mousemove", this.touchMove);
    canvas.addEventListener("touchmove", this.touchMove);
    canvas.addEventListener("mouseup", this.touchEnd);
    canvas.addEventListener("touchend", this.touchEnd);

    this.ctx = canvas.getContext("2d");

    this.brush = new Image();
    this.brush.src = brush;

    this.cover = new Image();
    this.cover.src = bingoUnscratched;
    this.cover.onload = () =>
      this.ctx.drawImage(this.cover, 0, 0, canvas.width, canvas.height);
  }
  componentWillUnmount() {
    const canvas = this.canvas;
    canvas.removeEventListener("mousedown", this.touchStart);
    canvas.removeEventListener("touchstart", this.touchStart);
    canvas.removeEventListener("mousemove", this.touchMove);
    canvas.removeEventListener("touchmove", this.touchMove);
    canvas.removeEventListener("mouseup", this.touchEnd);
    canvas.removeEventListener("touchend", this.touchEnd);
  }
  reloadCard=()=> {
    this.ctx = this.canvas.getContext("2d");
    this.ctx.globalCompositeOperation = "destination-over";
    this.ctx.fillStyle = "#f5873c";
    let width = 323;
    let height = 190;
    this.cover = new Image();
    this.cover.src = bingoUnscratched;
    this.ctx.drawImage(this.cover, 0, 0, width, height);
    this.ctx.fillRect(0, 0, width, height);
    this.ctx.save();
  }

  getPosition=(event)=> {
    let target = this.canvas;
    let offsetX = 0;
    let offsetY = 0;

    if (target.offsetParent !== undefined) {
      while ((target = target.offsetParent)) {
        offsetX += target.offsetLeft;
        offsetY += target.offsetTop;
      }
    }

    const x = (event.pageX || event.touches[0].clientX) - offsetX;
    const y = (event.pageY || event.touches[0].pageY) - offsetY;
    return { x, y };
  }

  touchStart=(event)=> {
    this.isDrawing = true;
    if (this.props.ticketCount == 0  ||  this.state.finished || this.state.loadingscratch) {
      this.isDrawing = false;
    }
    this.lastPoint = this.getPosition(event);
    this.ctx.globalCompositeOperation = "destination-out";
  }

  touchMove=(event)=> {
    if (!this.isDrawing) return;
    event.preventDefault();

    const ctx = this.ctx;
    const a = this.lastPoint;
    const b = this.getPosition(event);
    const dist = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
    const angle = Math.atan2(b.x - a.x, b.y - a.y);
    const offsetX = this.brush.width / 2;
    const offsetY = this.brush.height / 2;

    for (let x, y, i = 0; i < dist; i++) {
      x = a.x + Math.sin(angle) * i - offsetX;
      y = a.y + Math.cos(angle) * i - offsetY;
      ctx.drawImage(this.brush, x, y);
    }
    const pixelBuffer = new Uint32Array(
      this.ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      ).data.buffer
    );
    var count = pixelBuffer.reduce(function (n, val) {
      return n + (val === 0);
    }, 0);
    console.log("ciuynnn", count);

    if (count > 60637) {
      this.flipAll(event);
    }
    console.log("pix", this.isCanvasBlank());

    this.lastPoint = b;
  }
  isCanvasBlank=()=> {
    this.ctx = this.canvas.getContext("2d");

    const pixelBuffer = new Uint32Array(
      this.ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      ).data.buffer
    );

    console.log(pixelBuffer.some((color) => color !== 0));
    return !pixelBuffer.some((color) => color !== 0);
  }
  touchEnd=(event)=> {
    this.isDrawing = false;
  }

  reveal=()=> {
    this.setState({
      buyingms: null,
    });
    this.props.onPlayTicket(
      this.props.gameId,
      this.state.ticketId,
      this.state.serialNumber,
      7
    );
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // clear path
    this.ctx.beginPath();

    this.ctx.globalCompositeOperation = "destination-out";
    this.setState({
      finished: true,
    });
  }

  load=()=>{
    this.setState({
      buyingms: null,
    });
    this.setState({
      loadingscratch: true,
    });
    axios
      .get(url(localStorage.getItem("token")))
      .then((response) => {
        console.log("ticketBingo", response);
        if (response.data.status === 1 && response.data.tickets.length > 0) {
          this.setState({
            ticketId: response.data.tickets[0].id,
            ticketValues: response.data.tickets[0].ticketValues,
            serialNumber: response.data.tickets[0].serialNumber,
            loadingscratch: false,
          });
          if (response.data.tickets[0].prize != 0) {
            this.setState({
              winning: true,
              prize: response.data.tickets[0].prize,
            });
          }
        } else {
          this.setState({
            loadingscratch: false,
          });
        }
      })
      .catch(function (error) {
        this.setState({
          loadingscratch: false,
        });
        console.log(error);
      });
  }

  componentWillMount() {
    this.load();
  }
  playAgain=(event)=> {
      this.generateRandomColor();
    this.isFlipped = false;
    if(event)event.preventDefault();
    this.setState({
      playButton: false,
      loadingscratch: true,
      animal0: false,
      animal1: false,
      animal2: false,
      animal3: false,
      animal4: false,
      animal5: false,
      animal6: false,
      animal7: false,
      animal8: false,
      confetti: false,
      ticketId: null,
      serialNumber: null,
      ticketValues: [],
      winning: false,
      celerate: false,
      prize: null,
      freload: true,
      finished: false,
      lastTicketPlayed: this.props.ticketCount == 1,
    });
    this.setState({
      scratchAll: false,
    });
    this.reloadCard();
    axios
      .get(url(localStorage.getItem("token")))
      .then((response) => {
        this.setState({
          ticketId: response.data.tickets[0].id,
          ticketValues: response.data.tickets[0].ticketValues,
          serialNumber: response.data.tickets[0].serialNumber,
          loadingscratch: false,
        });
        if (response.data.tickets[0].prize != 0) {
          this.setState({
            winning: true,
            prize: response.data.tickets[0].prize,
            loadingscratch: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  flipAll=(event)=> {
    if (this.isFlipped) {
      return;
    }

    this.isFlipped = true;
    event.preventDefault();
    ReactGA.event({
      category: "Games",
      action: "Play Bingo Alphabet",
    });
    this.setState({
      animal0: true,
      animal1: true,
      animal2: true,
      animal3: true,
      animal4: true,
      animal5: true,
      animal6: true,
      animal7: true,
      animal8: true,
      scratchAll: true,
    });
    this.reveal();
  }
  flip=(index)=> {
    switch (index) {
      case 0:
        this.setState({
          animal0: !this.state.animal0,
        });
        break;
      case 1:
        this.setState({
          animal1: !this.state.animal1,
        });
        break;
      case 2:
        this.setState({
          animal2: !this.state.animal2,
        });
        break;
      case 3:
        this.setState({
          animal3: !this.state.animal3,
        });
        break;
      case 4:
        this.setState({
          animal4: !this.state.animal4,
        });
        break;
      case 5:
        this.setState({
          animal5: !this.state.animal5,
        });
        break;
      case 6:
        this.setState({
          animal6: !this.state.animal6,
        });
        break;
      case 7:
        this.setState({
          animal7: !this.state.animal7,
        });
        break;
      case 8:
        this.setState({
          animal8: !this.state.animal8,
        });
        break;
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    
    if (this.props.statusBa == 1 && this.props.ticketCount == 0) {
      this.setState({
        loadingscratch: true,
        finished: false,
        playButton: true,
      });
    }
    if (this.props.statusBa == 1) {
      this.setState({
        buyingms: (
          <div className="success">
            <i className="fa fa-check"></i>
            <span>Tickets achetés avec succès</span>
          </div>
        ),
        loadingscratch: false,
        finished: true,
        playButton: false,
        lastTicketPlayed:false
      });
    } else if (this.props.statusBa == 0) {
      this.setState({
        buyingms: (
          <div className="error" style={{ marginTop: "35px" }}>
            <i className="fa fa-exclamation-triangle"></i>
            <span>Une erreur s'est produite, veuillez réessayer plus tard</span>
          </div>
        ),
      });
    } else if (this.props.statusBa == 5) {
      this.setState({
        buyingms: (
          <div className="error" style={{ marginTop: "35px" }}>
            <i className="fa fa-exclamation-triangle"></i>
            <span>Montant insuffisant dans votre portefeuille</span>
          </div>
        ),
      });
    }
    if (this.props.statusBa == 99) {
      this.setState({
        buyingms: null,
      });
    }
  }
  componentWillUnmount() {
    this.setState({
      buyingms: null,
    });
  }
  render() {
    const { t } = this.props;
    let button = (
      <a
        className="scratch-all main-btn"
        href="#"
        onClick={this.flipAll}
        name="play"
      >
        {t("Révèle tout")}
      </a>
    );
    let { onClick, onCheck, onBuy } = this.props;
    let winningPrize = null;
    let confeti = null;
    let playagain = null;
    let highlight = "";
    if (
      this.state.animal0 &&
      this.state.animal1 &&
      this.state.animal2 &&
      this.state.animal3 &&
      this.state.animal4 &&
      this.state.animal5 &&
      this.state.animal6 &&
      this.state.animal7 &&
      this.state.animal8
    ) {
      ReactGA.event({
        category: "Games",
        action: "Play Bingo Alphabet",
      });

      if (this.state.winning && this.state.finished) {
        confeti = (
          <Confetti
            className="interval"
            active={this.state.confetti}
            config={config}
          />
        );
        winningPrize = (
          <div className="game-results-action right-aligned">
            <div className="winnings">
              <span>{t("vous avez gagné")}</span>
              <p>{this.state.prize} FCFA</p>
            </div>
          </div>
        );
        highlight = "highlight";
      } else if (this.state.finished) {
        confeti = "";
        highlight = "";
        winningPrize = (
          <div className="game-results-action right-aligned">
            <div className="winnings">
              <span style={{ marginTop: "40px" }}>
                {t("Essayez votre chance la prochaine fois")}
              </span>
            </div>
          </div>
        );
      }
    }
    console.log("ticket value", this.state.ticketValues);
    let mappedValues = this.state.ticketValues.map((pic, index) => (
      <div
        className={
          pic.isWinning
            ? "scratch-table-items african winning"
            : "scratch-table-items african"
        }
        key={index}
      >
        <div className="scratch-item">
          <img src={pic.image} />
        </div>
      </div>
    ));
    
    if (this.props.ticketCount == 0) {
        button = <span className="nomore-tickets">{t("acheter")}</span>;
        // ticket = '';
        // winningPrize = "";
      } else if (
        !this.state.lastTicketPlayed &&
        this.state.finished &&
        this.props.ticketCount > 0
      ) {
        button = (
          <a
            className="main-btn play-again"
            href="#"
            onClick={this.playAgain}
            name="play"
          >
            {t("Jouer")}
          </a>
        );
      }

    let loading = false;
    if (this.state.loadingscratch) {
      loading = (
        <div className="loading-container overlay">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      );
      button = "";
    }
    let ticket = (
      <div className={" scratch-table"}>
        <div className="scratch-table-row">{mappedValues}</div>
        {confeti}
      </div>
    );
    let loading1 = null;
    if (this.props.loading) {
      loading1 = (
        <div className="loading-container" style={{ marginTop: "30px" }}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      );
    }
    const containerStyle = {
      width: "323px",
      height: "190px",
      position: "relative",
      marginBottom: "30px",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
      paddingLeft: '50px',
      marginTop: '70px'
    };
    const resultStyle = {
      visibility: this.state.loaded ? "visible" : "hidden",
    };
    return (
      <div className="game-subcontainer loadable-block">
        {/* <h2>{t("Trouver") + " 3 " + t("lettres") + "!"}</h2> */}
        <h2>{t("DECOUVREZ TROIS (3) LETTRES IDENTIQUES ET GAGNEZ JUSQU’À 2.000.000 FCFA")}</h2>
        {loading}
        <br />
        <div className="only-mobile tablet">{winningPrize}</div>
        <div className={highlight + " scratch-overall scratch-grid bingo"}>

          <div className={this.state.cardColor + " bingo-card scratch-table-container updated-design"}>
            <img className="scratch-logo" src={bingoLogo}/>
            <p>Decouvre 3 montants identiques et gagnez 1 fois ce montant. Gagnez jusqu'a 100.000 FCFA</p>

            <div
              className="ScratchCard__Container"
              style={containerStyle}
              ref={this.canvaContainer}
            >
              <canvas
                style={{
                  position: "absolute",
                  zIndex: 2,
                }}
                ref={(el) => (this.canvas = el)}
              />
              <div className="secret absolute fill no-select flex justify-center items-center">
                {ticket}
              </div>
            </div>
            <img src={bingoticketprice} className="bingo-ticket-price" />
          </div>
          {button}
          <div className="non-mobile non-tablet">{winningPrize}</div>
        </div>
        <div className="buy-ticket-container new-design">
          <div className="scratch-tickets">
          <div className="ticket-price">
              <label>{t("PRIX DU TICKET")}</label>
              <span>{this.props.ticketPriceBa} FCFA</span>
            </div>
            <a
              className="main-btn"
              onClick={(event) => onBuy(event, 1)}
              href="#"
            >
              {t("Acheter 1 ticket")}
            </a>
            <a
              className="main-btn"
              onClick={(event) => onBuy(event, 5)}
              href="#"
            >
              {t("Acheter 5 tickets")}
            </a>
            <a
              className="main-btn"
              href="#"
              onClick={(event) => onBuy(event, 10)}
            >
              {t("Acheter 10 tickets")}
            </a>
            {loading1}
            {this.state.buyingms}

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unplayedTickets: state.auth.unplayed_scratch,
    ticketMessageAE: state.auth.ticketMessageAE,
    loading: state.auth.loading,
    statusBa: state.auth.statusBa,
    ticketPriceBa: state.auth.ba_ticketPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPlayTicket: (gameId, ticketId, serialNumber, scratchType) =>
      dispatch(actions.playTicket(gameId, ticketId, serialNumber, scratchType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate("translations")(BingoPlay));
