const logoutApi= (token, lang)=>`/user-api/tokens/${token}?language=${lang}`;
const userResetPassApi=(token,lang)=>`/user-api/tokens/${token}/passwords?language=${lang}`;
const userResetMailPassApi=(value,lang)=>`/user-api/emails/${value}/passwords?language=${lang}`;
const userResetMsisPassApi=(value,lang)=>`/user-api/msisdns/${value}/passwords?language=${lang}`;
const userMsisVerificationApi=(unvToken,unvMsis,lang)=>`/user-api/tokens/${unvToken}/msisdns/${unvMsis}/codes?language=${lang}`;
const authApi= (username, lang)=>`/user-api/users/${username}?language=${lang}`;
const verifyMsisdnApi= (token, lang)=>`/user-api/tokens/${token}/msisdns?language=${lang}`;
const registerApi= ( lang)=>`/user-api/users?language=${lang}`;
const authCheckStateApi= (token)=>`/user-api/tokens/${token}`;
const newsLetterApi = `/web-api/newsletter-emails`
const purchaseInstalottoApi= (lang)=>`/gameapi/instalottotickets?language=${lang}`;

const pick3tokensApi =(token,pageNumber)=>`/pick-three-api/tokens/${token}/tickets?pageSize=100&pageNumber=${pageNumber}`;
const purchasePick3Api= ()=>'/pick-three-api/tickets?isWallet=true';
const pick3drawsApi=(isComplete,pageNumber,pageSize,lang)=>`/pick-three-api/draws?isComplete=${isComplete}&pageNumber=${pageNumber}&pageSize=${pageSize}&language=${lang}`;
const pick3Hotnumbers=`/pick-three-api/hot-numbers`;

const changeMsisdnApi= ( lang)=>`/userapi/changemsisdn?language=${lang}`;
const userConfirmMailApi=(token)=>`/userapi/confirmemail?token=${token}`;
const userConfirmChangeMailApi=(token)=>`/userapi/confirmchangeemail?token=${token}`;
const unlockAccountApi=(token)=>`/userapi/unlockaccount?token=${token}`;
const userPinsApi=(token)=>`/userapi/tokens/${token}/pins`;

const getWalletApi= (token, lang)=>`/wallet-transaction-api/tokens/${token}/wallets?language=${lang}`;
const walletTransactionTicketsApi=(token, lang)=>`/wallet-transaction-api/tokens/${token}/tickets?pageSize=1000&pageNumber=0&language=${lang}`;
const walletTransactionsApi=(token,lang)=> `/wallet-transaction-api/tokens/${token}/transactions?pageSize=100000&pageNumber=0&language=${lang}`;
const walletAmountsApi=(append)=>`/wallet-transaction-api/amounts${append}`;

const scratchApi=(token,type,isFinished,pageSize,pageNumber)=>`/scratch-api/tokens/${token}/tickets?type=${type}&isFinished=${isFinished}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
const getScratchApi= (gametype)=>`/scratch-api/types/${gametype}?pageSize=1&pageNumber=0`;
const buyScratchApi= ( lang)=>`/scratch-api/tickets?isWallet=true&language=${lang}`;
const playTicketApi= ( lang)=>`/scratch-api/tickets?language=${lang}`;

const promotionsApi =(token,lang)=>`/transactionapi/promotions?token=${token}&language=${lang}`;

const termsandconditionApi=(lang)=>`/web-api/pagetypes/TermsAndCondition?PageNumber=1&PageSize=1&Language=${lang}`;


// ------------------------------------EXTERNAL-------------------------------------------------
const extLogin=(username)=>`/external-user-api/users/${username}`;
const extLogout=(sess)=>`​/external-user-api​/sessions​/${sess}`;//delete
const extRenew=(customerId,channel)=>`/external-user-api/customer/${customerId}/renew?Channel=${channel}`;
const extAuth=(session)=>`/external-user-api/sessions/${session}`;
const externalForgotPassword=`http://10.12.1.75:81/#/?page=forgot-password`;
const externalLogin='https://dmp.dme.systems:9443/api-gateway/user/auth';

export {logoutApi,purchaseInstalottoApi,purchasePick3Api,authApi,
    changeMsisdnApi,verifyMsisdnApi,registerApi,authCheckStateApi,getWalletApi,
    getScratchApi,buyScratchApi,playTicketApi,scratchApi,promotionsApi,walletTransactionTicketsApi,
    walletTransactionsApi,pick3drawsApi,userResetPassApi,userResetMailPassApi,userResetMsisPassApi,
    userMsisVerificationApi,termsandconditionApi,pick3tokensApi,pick3Hotnumbers,userConfirmMailApi,
    userConfirmChangeMailApi,unlockAccountApi,userPinsApi, walletAmountsApi,

    externalForgotPassword,externalLogin,extLogin,extLogout,extRenew,extAuth,newsLetterApi
};