import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {translate} from "react-i18next";
class AricanExplorerLogin extends Component {
    render() {

        const { t} = this.props;
        return (
            <div className="game-subcontainer game-loggedout">
                <div className="sratch-logout">
                    {/* <h2>{t("Trouvez votre animal!")}</h2> */}
                    <h4>TROUVEZ VOTRE ANIMAL ET GAGNEZ JUSQU’À 2.000.000 FCFA !</h4>
                    <Link className="login-game" to="/login">{t("CONNECTEZ-VOUS ET JOUEZ")}</Link>
                </div>
                <div className="extra-scratch ae absolute"></div>
            </div>
        );

    }
}

export default translate("translations")(AricanExplorerLogin);