import React, {Component} from "react";
import {translate} from "react-i18next";
import { fruit } from "../../../Components/UI/Library";

class ScratchSideMenu extends Component {
    constructor() {
        super();
        this.state = {
            menu: '',
        }
        this.openMenu = this.openMenu.bind(this);
    }
    openMenu(event) {
        event.preventDefault();
        if (this.state.menu === '') {
            this.setState({
                menu: 'open'
            })
        } else {
            this.setState({
                menu: ''
            })
        }
    }
    render() {
        const {t} = this.props;
        let {onClick, active} = this.props;
        let menu =  <ul className={active + " scratch-out"}>
            <li onClick={this.openMenu}><a href="#" onClick={onClick} name="play">{"Jouer"}</a></li>
            <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtoplay">{"Comment jouer ?"}</a></li>
            <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtowin">{"Comment gagner ?"}</a></li>
        </ul>;
        let balance = null;
        let scratchBalance = null;
        let loggedin = 'loggedout';
        if (this.props.loggedIn) {
            balance =  <div className="available-balance">
                <span>SOLDE DISPONIBLE</span>
                <h3>{this.props.balance + " FCFA"}</h3>
            </div>;
            menu = (
                <ul className={active}>
                    {/*<li onClick={this.openMenu}><a href="#" onClick={onClick} name="purchase">{t("Purchase")}</a></li>*/}
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="play">{"Jouer"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtoplay">{"Comment jouer ?"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="howtowin">{"Comment gagner ?"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="purchases">{"Achats"}</a></li>
                    <li onClick={this.openMenu}><a href="#" onClick={onClick} name="winnings">{"Mes gains"}</a></li>
                </ul>
            );
            scratchBalance =
                <div className="available-tickets">
                    <p>
                        <label>{t("TICKET(S) DISPONIBLE(S)")}</label>
                        <span>{this.props.unplayedTickets} {t("Ticket(s)")}</span>
                    </p>
                    {/*<a className="play-scratch" href="#" onClick={onClick} name="play">play</a>*/}
                </div>;
            loggedin = ''
        }
        return (
            <ul className=" nav nav-pills mr-auto flex-column">
                <div className={this.state.menu + " game-sidemenu scratch"}>
                    <div className="side-info-container">
                        <a className="fa fa-align-justify only-mobile" href="#" onClick={this.openMenu}></a>
                        {balance}
                        <div className={"game-logo logo-text " + loggedin}>
                            <img src={fruit} />
                            <span>Fruit de chance</span>
                        </div>
                        {scratchBalance}
                    </div>
                    {menu}
                </div>
            </ul>

        );
    }
}

export default translate("translations")(ScratchSideMenu);


