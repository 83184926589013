import React, { Component } from 'react';
import {Link} from "react-router-dom";

class PickPlay extends Component {
    render() {
        return (
            <div className="game-subcontainer game-loggedout">
                <div>
                    <h4>
                        {"CHOISISSEZ VOS NUMÉROS PRÉFÉRÉS ENTRE 0 ET 9 ET GAGNEZ AU TIRAGE ! "}
                    </h4>
                    {/* <p className="text-center">
                        {"Entre 0 et 9!"}
                            <br/><br/>
                    </p> */}
                    <Link className="login-game" to="/login">{"CONNECTEZ-VOUS ET JOUEZ"}</Link>
                </div>
            </div>
        );
    }
}

export default (PickPlay);