import React, { useState } from "react";
import { Trans, translate } from "react-i18next";

const input = (props) => {
  let inputElement = null;

  
  let value = "";
  if (props.value) {
    value = props.value;
  }

  switch (props.elementType) {
    case "date":
      inputElement = (
        <span className="date-container">
          <input
            type="date"
            min="1/1/1940"
            className={props.classes}
            {...props.elementConfig}
            value={value}
            onChange={props.changed}
            required={props.required}
          />
        </span>
      );

      break;
    case "input":
      inputElement = (
        <input
          className={props.classes}
          {...props.elementConfig}
          value={value}
          onChange={value == "Cote d'Ivoire" ? () => {} : props.changed}
          required={props.required}
          disabled={props.disabled ? props.disabled : false}
        />
      );
      break;
    
    case "textarea":
      inputElement = (
        <textarea
          className={`${props.classes} `}
          {...props.elementConfig}
          value={value}
          onChange={props.changed}
          required={props.required}
          er
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={props.classes}
          value={value}
          required={props.require}
          onChange={props.changed}
        >
          {props.elementConfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case "checkbox":
      inputElement = inputElement = (
        <div className="checkbox-input">
          <input
            type="checkbox"
            {...props.elementConfig}
            value={value}
            required="required"
            onChange={props.changed}
          />
        </div>
      );
      break;
    default:
      inputElement = (
        <input
          className={props.classes}
          {...props.elementConfig}
          value={value}
          onChange={props.changed}
          required="required"
        />
      );
  }
  let mobileClass = "";
  let mobileCode = "";
  if (props.extra == "Mobile") {
    mobileClass = "mobile-field";
    mobileCode = (
      <div className="mobile-code">
        <span>+225</span>
      </div>
    );
  } else if (props.extra == "terms") {
    mobileClass = "terms";
  }

  return (
    <div className={mobileClass}>
      <label>{props.label} </label>
      <div className="input-container">
        {mobileCode}
        {inputElement}
      </div>
    </div>
  );
};

export default input;
