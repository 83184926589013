import React, { Component } from "react";

class HowToWin extends Component {
    render() {
        return (
            <div className="insta-how">
                <div className="pick3 howtowin" style={{display: 'block'}}>
                    <div style={{width: '90%', margin: 'auto'}}>
                        {/* <h2>{"COMMENT GAGNER!"}</h2> */}
                        <h4>{"VOUS AVEZ PLUS D'UNE CHANCE DE GAGNER!"}</h4>
                        <ul>
                            <li><i>1</i><span>{"OBTENEZ 3 NUMÉROS EN ORDRE ET GAGNEZ 200 FOIS VOTRE MISE."}</span></li>
                            <li><i>2</i><span>{"OBTENEZ 2 NUMÉROS EN ORDRE ET GAGNEZ 10 FOIS VOTRE MISE.\n"}</span></li>
                            <li><i>3</i><span>{"OBTENEZ LE DERNIER NUMÉRO  ET GAGNEZ 2 FOIS VOTRE MISE.\n"}</span></li>
                        </ul>
                    </div>
                </div>

            </div>
        );
    }
}
export default (HowToWin);
