import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import * as actions from "./store/actions/index";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import authReducer from "./store/reducers/auth";
import axios from "axios";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const { REACT_APP_API_ENDPOINT, REACT_APP_CONSOLE } = process.env;

axios.defaults.baseURL = REACT_APP_API_ENDPOINT;
// axios.defaults.baseURL = 'https://api.test-ci.moobifun.com/';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// let ws = new WebSocket("wss://dmp.dme.systems:9443/stomp");

const rootReducer = combineReducers({
  auth: authReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

 if (!REACT_APP_CONSOLE) console.log = () => {};

const Middlewear = () => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  useEffect(() => {
    if (
      state.auth.token !== null &&
      state.auth.stompConnected == false &&
      localStorage.getItem("session_id")
    )
      dispatch(actions.stompConnect());
  }, [state.auth.token]);

  return <App />;
};

const app = (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <Middlewear />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

ReactDOM.render(app, document.getElementById("root"));
registerServiceWorker();
