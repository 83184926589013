import React, { Component } from "react";
import { connect } from 'react-redux';
import {translate} from "react-i18next";

class HowToWin extends Component {

    render() {

        return (
            <div className="insta-how scratch-how">
                <div className="pick3" style={{display: 'block'}}>
                    <div className=" online" className="play-method" >
                        <div className="scratch-howtowin">
                            {/* <h2>{t("Comment gagner")}</h2> */}
                            <div className="sport-how-win">
                                <h4>DECOUVREZ TROIS (3) MONTANTS IDENTIQUES EN GRATTANT ET GAGNEZ UNE (1) FOIS CE MONTANT</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        prizevalues: state.auth.af_prizeValues,
    };
};



export default connect( mapStateToProps )(translate("translations")( HowToWin ));